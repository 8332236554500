import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import { saveOutline } from "ionicons/icons";
import _ from "lodash";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import GalleryItem from "./GalleryItem";
import ListItem from "./ListItem";
import PhotoGallery from "./PhotoGallery";

interface Props {
  showGroupModal: () => void;
}

export default function PromotedGroups(props: Props) {
  const [promotedItems, _promotedItems] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.groups, [
      Query.equal("isPromoted", true),
    ]).then((groupData) => {
      console.log("promotedItems", groupData);
      _promotedItems(groupData.documents.filter((x: any) => x.lat == null));
    });
  }, []);

  function PromotedList() {
    return (
      promotedItems &&
      promotedItems[0] &&
      _.orderBy(promotedItems, "rep", "desc").map(
        (item: any, index: number) => {
          return <ListItem key={index} item={item} />;
        }
      )
    );
  }

  return (
    <div>
      {/* <div
        style={{
          marginTop: 5,
          fontSize: 20,
          fontWeight: 900,
          display: "inline-block",
          width: "50%",
        }}
      >
        🏡 Home
      </div> */}
      {/* <div
        style={{ textAlign: "right", display: "inline-block", width: "50%" }}
      ></div>
      <div className="lines"></div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <IonButton
          size="small"
          color="light"
          expand="block"
          onClick={() => {
            props.showGroupModal();
          }}
        >
          ➕ <div style={{ fontSize: 12 }}> &nbsp;&nbsp;NEW</div>
        </IonButton>
      </div> */}
      <div style={{ textAlign: "center" }}>
        {promotedItems && PromotedList()}
      </div>
    </div>
  );
}
