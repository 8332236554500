import { Client, Account, ID, Teams, Storage } from 'appwrite';
import { BUCKET_ID, HOST_URI, PROJECT_ID } from './db';


const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

const Store = new Storage(client);

export function imageUrl(id, preview = true, quality = 60, size = 600, map = false) {

    if (preview == false) {
        return Store.getFileView(BUCKET_ID.images, id, size, size, undefined, quality, undefined, undefined, undefined, undefined, undefined, undefined, undefined).href;
    } else if (preview == true && map == false) {
        return Store.getFilePreview(BUCKET_ID.images, id, 300, 300, undefined, 50, undefined, undefined, undefined, undefined, undefined, undefined, undefined).href
    } else {
        return Store.getFilePreview(BUCKET_ID.images, id, size, size, undefined, quality, 4, "000000", 8, undefined, undefined, undefined, "webp").href
    }

}

export function videoUrl(id) {
    return HOST_URI + "/storage/buckets/" + BUCKET_ID.video + "/files/" +
        id +
        "/view?project=" + PROJECT_ID;
}




export function audioUrl(id) {
    return HOST_URI + "/storage/buckets/" + BUCKET_ID.audio + "/files/" +
        id +
        "/view?project=" + PROJECT_ID;
}




export default Store;