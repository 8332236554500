import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import EmojiPicker from "emoji-picker-react";
import {
  close,
  homeOutline,
  personOutline,
  serverOutline,
} from "ionicons/icons";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useRecoilState } from "recoil";
import MyProfile from "../components/MyProfile";
import SavedGroups from "../components/SavedGroups";
import CheckoutCredits from "../services/checkout";
import Checkout from "../services/checkout";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, userState, userTeamState } from "../services/state";
import User from "../services/users";

function CreditsSuccess() {
  const [picked, _picked] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [credits, _credits] = useState<any>();

  const params = useParams<{ sessionId: string }>();

  const history = useHistory();

  let created = false;

  useEffect(() => {
    User.getAccount().then((res: any) => {
      _account(res);
    });
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.credits, [
      Query.equal("userId", account.$id),
    ]).then((res: any) => {
      if (res.total != 0) {
        _credits(res.documents[0].credits);
      }
    });
    if (params.sessionId && !created) {
      created = true;
      Db.listDocuments(DATABASE_ID, "6410b0ec05622ec158ee", [
        Query.equal("sessionId", params.sessionId),
      ]).then((res: any) => {
        if (res.total == 0) {
          Db.createDocument(DATABASE_ID, "6410b0ec05622ec158ee", "unique()", {
            accountId: account.$id,
            sessionId: params?.sessionId,
          }).then((res: any) => {
            console.log(res);
          });
        } else {
          if (!res.documents[0].accountId) {
            console.log("Already exists");
            Db.updateDocument(
              DATABASE_ID,
              "6410b0ec05622ec158ee",
              res.documents[0].$id,
              {
                accountId: account.$id,
              }
            );
          }
        }
      });
    }
  }, []);

  return (
    <IonPage>
      <div
        style={{
          fontWeight: "bolder",
          fontSize: 50,
          width: "100%",
        }}
        onClick={() => history.replace("/credits")}
      >
        <IonIcon style={{ fontSize: 54 }} icon={close} />
      </div>
      <IonContent>
        <div
          style={{
            marginTop: 20,
            fontSize: 32,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Credits Success
        </div>
        {params?.sessionId}
      </IonContent>
    </IonPage>
  );
}

export default CreditsSuccess;
