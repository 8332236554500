import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db, { DATABASE_ID } from "../../services/db";
import { imageUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";

interface Props {
  item: any;
  emoji: string;
  isPublic?: boolean;
}

export default function PageGorilla(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const history = useHistory();
  useEffect(() => {
    Db.listDocuments(DATABASE_ID, "users", [
      Query.equal("accountId", props.item.createdBy),
    ]).then((data) => {
      // console.log("createdBy", data.documents[0]);
      _createdBy(data.documents && data.documents[0]);
    });
  }, []);

  // console.log("item", props.item);
  return (
    <>
      <IonPage>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol
                size="8"
                style={{ paddingTop: 14, fontSize: 34, fontWeight: 900 }}
              >
                <div style={{ display: "inline-block" }}>
                  <Emoji size={38} unified={props.emoji} />
                </div>
                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                  &nbsp; {emojisSelectUnified(props.emoji)?.label}
                </div>
              </IonCol>
              <IonCol
                onClick={() => {
                  history.replace("/group/" + props.item.groupId);
                }}
                style={{ textAlign: "right" }}
              >
                <IonIcon style={{ fontSize: 54 }} icon={close} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent className="ion-padding">
          <div style={{ fontWeight: 900, fontSize: 54 }}>{props.item.name}</div>
          <div style={{ width: 20, display: "inline-block" }}>
            {/* <IonAvatar>
              <img
                alt="Silhouette of a person's head"
                src={
                  createdBy && createdBy.imageId && imageUrl(createdBy.imageId)
                }
              />
            </IonAvatar> */}
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <div className="" style={{ textAlign: "left", width: "100%" }}>
              {createdBy?.username}
            </div>
            <div
              className="block-subtext"
              style={{ textAlign: "left", width: "100%" }}
            >
              {moment(props.item.$createdAt).fromNow()}
            </div>
          </div>
          <div style={{ fontSize: 54 }}>{props.item.body}</div>
        </IonContent>
      </IonPage>
    </>
  );
}
