import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db, { DATABASE_ID } from "../../services/db";
import { imageUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";
import { LeafPoll, Result } from "react-leaf-polls";
import "react-leaf-polls/dist/index.css";
import _, { toInteger } from "lodash";

interface Props {
  item: any;
  emoji: string;
  showDetails: boolean;
}

export default function BlockPoll(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const history = useHistory();
  const resData = JSON.parse(props.item.body);

  const customTheme = {
    textColor: "black",
    mainColor: "#00B87B",
    backgroundColor: "rgb(255,255,255)",
    alignment: "center",
  };

  function vote(item: Result, results: Result[]) {
    localStorage.setItem("votedId-" + props.item.$id, item.id.toString());
    console.log("results", results);
    Db.updateDocument(DATABASE_ID, "blocks", props.item.$id, {
      body: JSON.stringify(results),
    }).then((data) => {
      console.log(data);
    });
  }

  // console.log("item", props.item);
  return (
    <>
      <div
        style={{ paddingTop: 0 }}
        onClick={() => {
          history.push(
            "/group/" + props.item.groupId + "/block/" + props.item.$id
          );
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            width: 34,
            paddingTop: 10,
          }}
        >
          {props && props.item && props.item.emoji && (
            <Emoji unified={props.item.emoji} size={28} />
          )}
        </div>
        <div
          style={{
            display: "inline-block",
            padding: 8,
            borderRadius: 8,
            width: "calc(100% - 40px)",
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 900 }}>
            {props.item.name}
            <br />
            {/* <StoredImage id={itemProps.item.imageId} style={{width:"100%",height:100}} /> */}
          </div>
          <div style={{ width: "100%" }}>
            &nbsp; &nbsp;
            {_.sumBy(resData, "votes")} Voted
          </div>
          <div
            className="block-subtext"
            style={{ textAlign: "left", width: "100%" }}
          >
            {moment(props.item.$createdAt).fromNow()}
          </div>
        </div>
      </div>
    </>
  );
}
