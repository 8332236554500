import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import EmojiPicker from "emoji-picker-react";
import {
  close,
  homeOutline,
  personOutline,
  serverOutline,
} from "ionicons/icons";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import MyProfile from "../components/MyProfile";
import SavedGroups from "../components/SavedGroups";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, userState, userTeamState } from "../services/state";
import User from "../services/users";

function Me() {
  const [picked, _picked] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [credits, _credits] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    User.getAccount().then((res: any) => {
      Db.listDocuments(DATABASE_ID, COLLECTION_ID.credits, [
        Query.equal("userId", res.$id),
      ]).then((res: any) => {
        if (res.total != 0) {
          _credits(res.documents[0]);
        }
      });
    });
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div
          style={{
            fontWeight: "bolder",
            fontSize: 50,
            width: "100%",
          }}
          onClick={() => history.goBack()}
        >
          <IonIcon style={{ fontSize: 54 }} icon={close} />
        </div>
        <div style={{ marginTop: 10 }}>
          <h2 style={{ textAlign: "center" }}>Update your profile</h2>
          <MyProfile name={"Me"} />
        </div>
        {credits && (
          <div style={{ textAlign: "center", width: "100%" }}>
            {(credits.subscription_basic && (
              <>
                <h2>Basic Subscription</h2>
                <p>
                  Your have a basic subscription. You can upload unlimited
                  images per month.
                </p>
                <IonButton href="https://billing.stripe.com/p/login/4gw5lpcaobU6a8odQQ">
                  Manage Subscription
                </IonButton>
              </>
            )) ||
              (credits.subscription_pro && (
                <>
                  <h2>Pro Subscription</h2>
                  <p>
                    Your have a pro subscription. You can upload unlimited
                    images per month and others can upload unlimited images to
                    your collections.
                  </p>
                  <IonButton href="https://billing.stripe.com/p/login/4gw5lpcaobU6a8odQQ">
                    Manage Subscription
                  </IonButton>
                </>
              )) || (
                <>
                  <h2 style={{ textAlign: "center" }}>Credits</h2>
                  <div
                    onClick={() => history.push("/credits")}
                    style={{
                      textAlign: "center",
                      marginBottom: 100,
                      textDecoration: "underline",
                    }}
                  >
                    You have <b>{credits?.credits}</b> credits. <br />
                    Every time you upload an image to Tinkan, you use a credit.
                    <br />
                    <br />
                    <b>Click here to buy more credits</b>
                    <br />
                    <br />
                  </div>
                </>
              )}
            <div style={{ margin: 50 }}>
              <a href="/tos.html">Terms of Use</a>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default Me;
