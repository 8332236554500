import { IonAvatar, IonCol, IonGrid, IonRow } from "@ionic/react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  Avatar,
  MessageInput,
} from "@chatscope/chat-ui-kit-react";
import { Query } from "appwrite";
import { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { imageUrl } from "../services/storage";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";
import { useRecoilState } from "recoil";
import { userState } from "../services/state";
import moment from "moment";
import { HashLink } from "react-router-hash-link";
import _ from "lodash";
import ChatMessage from "./ChatMessage";
import ChatGlobalCode from "./ChatGlobalCode";
import { group } from "console";

interface Props {
  relatedId: string;
}

export default function Chat(props: Props) {
  const [messages, setMessages] = useState<any[]>([]);
  const [user, _user] = useRecoilState<any>(userState);
  const [users, setUsers] = useState<any[]>([]);
  const [messagesEnd, _messagesEnd] = useState<any>();

  const history = useHistory();

  const el = useRef<any>();

  function scrollToBottom() {
    if (el.current === null) {
    } else el!.current!.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  const stopPropagation = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.messages, [
      Query.equal("relatedId", props.relatedId),
    ]).then((res) => {
      let userIds = [];
      for (let m of res.documents) {
        userIds.push(m.userId);
      }
      Db.listDocuments(DATABASE_ID, COLLECTION_ID.users, [
        Query.equal("$id", userIds),
      ]).then((res) => {
        console.log(res);
        setUsers(res.documents);
      });
      setMessages(res.documents);
    });
  }, [props.relatedId]);

  function getSender(id: string) {
    let user = users.find((u) => u.$id == id);
    if (user) {
      console.log(user);
      return user;
    }
    return "Somebody";
  }

  function List() {
    return (
      messages &&
      _.orderBy(messages, "$createdAt", "asc").map((item) => {
        if (
          item.message.match(
            /(^|\s)([23456789C][23456789CFGHJMPQRV][23456789CFGHJMPQRVWX]{6}\+[23456789CFGHJMPQRVWX]{2,3})(\s|$)/
          ) ||
          item.message.match(
            /(^|\s)([23456789CFGHJMPQRVWX]{4,6}\+[23456789CFGHJMPQRVWX]{2,3})(\s|$)/
          )
        ) {
          return (
            <ChatGlobalCode
              message={item}
              sender={getSender(item.userId)}
              user={user}
            />
          );
        } else {
          return (
            <ChatMessage
              message={item}
              sender={getSender(item.userId)}
              user={user}
            />
          );
        }
      })
    );
  }

  function DropPin() {
    let lats: any = [];
    let lons: any = [];
    let intervalCount = 0;

    let interval = setInterval(() => {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.longitude != 0) {
          lats.push(position.coords.latitude);
          lons.push(position.coords.longitude);
          intervalCount++;
          console.log(
            "position:",
            position.coords.longitude,
            position.coords.latitude,
            intervalCount
          );
        }

        // Kill it
        if (intervalCount == 4) {
          clearInterval(interval);

          Db.createDocument(DATABASE_ID, COLLECTION_ID.messages, "unique()", {
            relatedId: props.relatedId,
            message: position.coords.longitude + "," + position.coords.latitude,
            userId: user.$id,
          }).then((res) => {
            setMessages([...messages, res]);
          });
        }
        if (intervalCount > 4) {
          clearInterval(interval);
        }
      });
    }, 1000);
  }
  return (
    <>
      <ChatContainer>
        <MessageList>{List()}</MessageList>

        {user && user.$id && (
          <MessageInput
            onSend={(e) => {
              if (users.find((u) => u.$id == user.$id) == undefined) {
                users.push(user);
                setUsers(users);
              }

              console.log(e);
              Db.createDocument(
                DATABASE_ID,
                COLLECTION_ID.messages,
                "unique()",
                {
                  relatedId: props.relatedId,
                  message: e,
                  userId: user.$id,
                }
              ).then((res) => {
                setMessages([...messages, res]);
              });
            }}
            attachButton={false}
            placeholder="Add some chatter..."
            style={{
              width: "100%",
              zIndex: 9999999999999999999999999999999999,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
              paddingBottom: 40,
            }}
          />
        )}
      </ChatContainer>
      <div id={"el"} ref={el} style={{ height: 80, width: "100%" }}>
        <HashLink to="#chat_container"></HashLink>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 40,
          left: 10,
          right: 10,
          height: 45,
          width: 45,
          textAlign: "center",
          fontSize: 18,
          paddingTop: 5,
          backgroundColor: "lightblue",
          borderRadius: 50,
          border: "3px solid black",
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
        }}
        onClick={() => {}}
      >
        📍
      </div>
    </>
  );
}
