import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import User from "../../services/users";
import EmojiSelector from "../EmojiSelector";
import { useForm } from "react-hook-form";
import Microphone from "./Microphone";
import { Permission, Role } from "appwrite";
import { audioUrl } from "../../services/storage";
import AudioPlayer from "react-h5-audio-player";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { subscriptionState } from "../../services/state";
import { appFunction } from "../../services/function";
import { group } from "console";

interface Props {
  emoji: string;
  group?: string;
  block?: string;
  submitted: () => void;
}

export default function InputImage(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [type, _type] = useState<any>();
  const [block, _block] = useState<any>();
  const [audioId, _audioId] = useState<any>();
  const [audioSaved, _audioSaved] = useState(false);
  const [subscription, _subscription] = useRecoilState<any>(subscriptionState);
  const [sendUpdate, _sendUpdate] = useState(false);

  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useState<any>();
  const history = useHistory();

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (props.block) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.blocks, props.block).then(
        (data: any) => {
          setValue("body", data.body);
          setValue("name", data.name);
          _name(data.name);
          _body(data.body);
          _block(data);
          _audioId(data.audioId);
        }
      );
    }
  }, []);

  const onSubmit = (data: any) => {
    console.log(data);
    showLoading();

    if (props.group) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, props.group).then(
        (groupData: any) => {
          Db.createDocument(
            DATABASE_ID,
            COLLECTION_ID.blocks,
            "unique()",
            {
              name: data.name,
              emoji: props.emoji,
              body: data.body,
              groupId: props.group,
              createdBy: user.$id,
            },
            [
              Permission.read(Role.any()), // Writers can update this document
              Permission.update(Role.user(user.$id)), // Writers can update this document
              Permission.delete(Role.user(user.$id)), // Writers can update this document
            ]
          ).then((blockData) => {
            if (!props.group) return;
            dismissLoading();
            props.submitted();
          });
        }
      );
    } else if (props.block) {
      // update block
      Db.updateDocument(DATABASE_ID, COLLECTION_ID.blocks, props.block, {
        name: data.name,
        body: data.body,
      }).then(async (blockData) => {
        // alert("Block updated" + name);
        if (name == null && data.name && sendUpdate == true) {
          // alert("Block updated" + data.group);
          // get group name
          await Db.getDocument(
            DATABASE_ID,
            COLLECTION_ID.groups,
            block.groupId
          ).then(async (groupData) => {
            let message = data.name;
            if (groupData.name) {
              message = groupData.name + "\n \n" + message;
              message =
                message + "\n \n https://a.tinkan.app/group/" + groupData.$id;
            }

            await appFunction
              .createExecution(
                "GroupUpdate",
                JSON.stringify({
                  groupId: block.groupId,
                  message: message,
                }),
                false
              )
              .then((execution) => {
                console.log("execution", execution);
              });
          });
        }
        dismissLoading();
        props.submitted();
      });
    }
  };

  useEffect(() => {
    User.getAccount().then((data: any) => {
      _user(data);
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {/* {subscription &&
          (subscription.subscription_basic ||
            subscription.subscription_premium) ? null : (
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: "#666",
              }}
              onClick={() => {
                history.push("/credits");
              }}
            >
              ⏱ Need More Time?
            </div>
          )} */}
        </div>
        {audioId && (
          <div>
            <AudioPlayer
              className="rhap_loop--off	"
              style={{
                width: "100%",
                borderBottom: "0px solid #ccc !important",
                boxShadow: "0px 0px 0px 0px #ccc !important",
              }}
              autoPlay={false}
              showSkipControls={false}
              src={audioUrl(audioId)}
              onPlay={(e) => console.log("onPlay")}
              onEnded={(e) => {
                // if (!user || !user.$id) {
                // } else {
                //   _showMeHow(true);
                //   history.goBack();
                // }
              }}
              // other props here
            />
            {audioSaved && (
              <div
                style={{
                  color: "green",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                🙌 Success!! Your audio was updated for this picture 🙌
              </div>
            )}
          </div>
        )}
        {/* <hr
          style={{
            height: 16,
            width: "calc(100% + 36px)",
            marginLeft: -18,
            marginTop: 20,
            backgroundColor: "#ccc",
          }}
        /> */}
        {/* <h1>Details</h1> */}
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 30,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Message
          </IonLabel>
          <IonTextarea
            {...register("name")}
            rows={6}
            placeholder="Text goes here"
          />
        </div>
        <div
          style={{
            backgroundColor: "#eee",
            marginTop: 16,
            borderRadius: 8,
            padding: 8,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Subtitle
          </IonLabel>
          <IonInput
            {...register("body")}
            type="text"
            placeholder="Text goes here "
          />
        </div>
        {!name && (
          <div
            style={{
              marginTop: 16,
              borderRadius: 8,
              padding: 8,
            }}
          >
            <IonToggle
              checked={sendUpdate}
              onIonChange={(e) => {
                _sendUpdate(e.detail.checked);
              }}
              {...register("send")}
            />
            <IonLabel style={{ fontWeight: 900 }} position="stacked">
              📢 Send update to group
            </IonLabel>
          </div>
        )}
        <IonButton
          type="submit"
          onClick={() => {}}
          style={{ marginTop: 30 }}
          expand="block"
          color="dark"
          shape="round"
          size="large"
        >
          {(sendUpdate == true && " and send 🚀") || "Save 👍"}
        </IonButton>
        {props.block && (
          <div>
            <IonButton
              type="submit"
              size="small"
              onClick={() => {
                if (!props.block) return;
                Db.deleteDocument(
                  DATABASE_ID,
                  COLLECTION_ID.blocks,
                  props.block
                ).then(() => {
                  setTimeout(() => {
                    props.submitted();
                  }, 3000);
                });
              }}
              style={{ marginTop: 160 }}
              expand="block"
              color="light"
              shape="round"
            >
              Delete (no turning back)🙈
            </IonButton>
          </div>
        )}
      </form>
    </>
  );
}
