import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db from "../../services/db";
import User from "../../services/users";
import EmojiSelector from "../EmojiSelector";
import { useForm } from "react-hook-form";

interface Props {
  emoji: string;
  group?: string;
  block?: string;
  submitted: () => void;
}

export default function InputFile(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [type, _type] = useState<any>();
  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useState<any>();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    // showLoading();

    //   Db.createDocument(DATABASE_ID, COLLECTION_ID.blocks, "unique()", {
    //     name: data.name,
    //     emoji: props.emoji,
    //     body: data.body,
    //     groupId: props.group.$id,
    //     createdBy: user.$id,
    //   }).then((blockData) => {
    //     Db.updateDocument(DATABASE_ID, COLLECTION_ID.groups, props.group.$id, {
    //       lastActivity: moment(),
    //     }).then(() => {
    //       dismissLoading();
    //       props.submitted();
    //     });
    //   });
  };

  useEffect(() => {
    User.getAccount().then((data: any) => {
      _user(data);
    });
  }, []);

  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Write Your Fact
          </IonLabel>
          <IonInput {...register("name")} type="text" placeholder="Text goes here" />
        </div>
        <div
          style={{
            backgroundColor: "#eee",
            marginTop: 16,
            borderRadius: 8,
            padding: 8,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Upload File
          </IonLabel>
          <label>
            <input {...register("file")} accept="application/pdf" type="file" />
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: 5,
                textAlign: "center",
                borderRadius: 16,
                marginTop: 12,
              }}
            >
              Upload PDF
            </div>
          </label>
        </div>
        <IonButton
          type="submit"
          onClick={() => {}}
          style={{ marginTop: 30 }}
          expand="block"
          color="dark"
          shape="round"
        >
          Save
        </IonButton>
      </form> */}
      <h1>Still working on this</h1>
    </>
  );
}
