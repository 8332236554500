import {
  IonAvatar,
  IonButton,
  IonCol,
  IonFabButton,
  IonGrid,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import * as _ from "lodash";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { userState } from "../services/state";
import { imageUrl } from "../services/storage";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";

interface Props {
  clicked: (emoji: any) => void;
  group: any;
}

export const emojiList = [
  { label: "Remind", unified: "1f9e0", default: false },
  { label: "Question", unified: "1f91a", default: false },
  { label: "Message", unified: "1f4ac", default: true },
  { label: "Contact", unified: "260e-fe0f", default: false },
  { label: "Fact", unified: "1f914", default: true },
  { label: "Event", unified: "1f4c5", default: true },
  { label: "File", unified: "1f5c3-fe0f", default: false },
  { label: "Alarm", unified: "1f6a8", default: false },
  { label: "Link", unified: "1f517", default: true },
  { label: "Poll", unified: "1f4ca", default: false },
  { label: "Directions", unified: "1f697", default: true },
  { label: "Gorilla Text", unified: "1f98d", default: true },
  { label: "Image", unified: "1f4f8", default: false },
];

export function emojisSelectUnified(unified: string) {
  return _.find(emojiList, function (o) {
    return o.unified == unified;
  });
}

export default function EmojiSelector(props: Props) {
  const [selected, _selected] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);

  const history = useHistory();

  function isOwner() {
    if (user && props.group && user.$id == props.group.createdBy) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      {!selected && emojiList && props.group ? (
        <>
          {emojiList
            .filter((x) => x.default == true)
            .map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    textAlign: "center",
                    width: 70,
                    margin: 10,
                    fontWeight: "bold",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    _selected(item.unified);
                    props.clicked(item.unified);
                  }}
                >
                  <div style={{ marginLeft: 8 }}>
                    <IonFabButton
                      color={selected == item.unified ? "dark" : "light"}
                    >
                      <Emoji unified={item.unified} />
                    </IonFabButton>
                  </div>
                  <div style={{ fontSize: 12, paddingTop: 3 }}>
                    {item.label}
                  </div>
                </div>
              );
            })}
          {isOwner() && (
            <div style={{ marginTop: 30 }}>
              <IonButton
                onClick={() => {
                  history.push("/powerups/" + props.group.$id);
                }}
              >
                Power Ups
              </IonButton>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              textAlign: "center",
              width: 70,
              margin: 10,
              fontWeight: "bold",
              display: "inline-block",
            }}
            onClick={() => {
              _selected(undefined);
              props.clicked(undefined);
            }}
          >
            <div style={{ marginLeft: 8 }}>
              <IonFabButton color={selected == selected ? "dark" : "light"}>
                <Emoji unified={selected} />
              </IonFabButton>
            </div>
            <div style={{ fontSize: 12, paddingTop: 3 }}>
              {emojisSelectUnified(selected)?.label}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
