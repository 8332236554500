import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close, contract } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db from "../../services/db";
import { imageUrl } from "../../services/storage";
import VCardFileCreator from "../../services/vcard";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";

interface Props {
  item: any;
  emoji: string;
  isPublic?: boolean;
}

export default function PageContact(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    Db.listDocuments("tinkan", "users", [
      Query.equal("accountId", props.item.createdBy),
    ]).then((data) => {
      // console.log("createdBy", data.documents[0]);
      _createdBy(data.documents && data.documents[0]);
    });
  }, []);

  let contact = JSON.parse(props.item.body);

  // console.log("item", props.item);
  return (
    <>
      <IonPage>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol
                size="8"
                style={{ paddingTop: 14, fontSize: 34, fontWeight: 900 }}
              >
                <div style={{ display: "inline-block" }}>
                  <Emoji size={38} unified={props.emoji} />
                </div>
                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                  &nbsp; {emojisSelectUnified(props.emoji)?.label}
                </div>
              </IonCol>
              <IonCol
                onClick={() => {
                  history.replace("/group/" + props.item.groupId);
                }}
                style={{ textAlign: "right" }}
              >
                <IonIcon style={{ fontSize: 54 }} icon={close} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent className="ion-padding">
          <div style={{ fontWeight: 900, fontSize: 34 }}>{props.item.name}</div>
          <div style={{ width: 70, display: "inline-block" }}>
            <IonAvatar>
              <img
                alt=""
                src={
                  createdBy && createdBy.imageId && imageUrl(createdBy.imageId)
                }
              />
            </IonAvatar>
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <div className="" style={{ textAlign: "left", width: "100%" }}>
              {createdBy?.username}
            </div>
            <div
              className="block-subtext"
              style={{ textAlign: "left", width: "100%" }}
            >
              {moment(props.item.$createdAt).fromNow()}
            </div>
          </div>
          <div style={{ fontWeight: "bold", fontSize: "small", marginTop: 20 }}>
            Details:
          </div>
          <div style={{ fontSize: 18, fontWeight: "bold" }}>
            {contact?.firstName} {contact?.lastName}
          </div>
          <div style={{ fontStyle: "italic" }}>{contact?.location}</div>
          <div>
            <b>Title: </b> {contact?.title}
          </div>
          <div>
            <b>Work: </b> <a href={"tel:" + contact?.work}>{contact?.work}</a>
          </div>
          <div>
            <b>Mobile: </b>
            <a href={"tel:" + contact?.mobile}>{contact?.mobile}</a>
          </div>
          <div>
            <b>Email: </b>
            <a href={"mailto:" + contact?.email}>{contact?.email}</a>
          </div>

          <div>
            <b>Website: </b>
            <a href={contact?.website} target="_blank">
              {contact?.website}
            </a>
          </div>
          <div style={{ paddingTop: 30 }}>
            <VCardFileCreator employee={contact} className="name" />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
