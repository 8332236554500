import { IonIcon } from "@ionic/react";
import { flameOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { imageUrl } from "../services/storage";
import StoredImage from "./StoredImage";

interface Props {
  item: any;
}

export default function ListItem(props: Props) {
  const history = useHistory();

  //console.log("item", props.item);
  return (
    <div
      style={{
        textAlign: "left",
        display: "inline-block",
        verticalAlign: "top",
        width: "100%",
        margin: 5,
      }}
      onClick={() => {
        history.push("/group/" + props.item.$id);
      }}
    >
      <div
        style={{
          border:
            props.item.activity != true ? "solid 4px black" : "solid 4px green",
          width: 72,
          height: 72,
          borderRadius: 16,
          marginBottom: 6,
          boxShadow: "-2px 4px 0px 0px #005e5a",
          overflow: "hidden",
          display: "inline-block",
        }}
      >
        <StoredImage
          src={imageUrl(props.item.imageId)}
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            objectPosition: "top",
          }}
        />
      </div>

      <div
        style={{
          fontSize: 16,
          verticalAlign: "top",
          fontWeight: 900,
          display: "inline-block",
          marginLeft: 10,
          width: "calc(100% - 90px)",
        }}
      >
        {props.item.name.substring(0, 56)}
        <div style={{ fontSize: 12, fontWeight: "normal", color: "grey" }}>
          {props.item.description.substring(0, 100)}
        </div>

        <div
          style={{
            fontSize: 12,
            backgroundColor: "#eee",
            width: 80,
            textAlign: "left",
            borderRadius: 4,
            padding: 4,
            display: "inline-block",
          }}
        >
          😀 &nbsp;&nbsp; {props.item.rep + 1}
        </div>

        {props.item.lon ? (
          <a
            href={`https://www.google.com/maps/dir/?api=1&destination=${props.item.lat},${props.item.lon}`}
            target="_blank"
          >
            <div
              style={{
                fontSize: 12,
                backgroundColor: "#eee",
                width: 100,
                textAlign: "left",
                borderRadius: 4,
                padding: 4,
                marginLeft: 4,
                display: "inline-block",
              }}
            >
              🗺 &nbsp;&nbsp; Navigate
            </div>
          </a>
        ) : null}
      </div>
    </div>
  );
}
