import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import User from "../../services/users";
import EmojiSelector from "../EmojiSelector";
import { useForm } from "react-hook-form";

interface Props {
  emoji: string;
  group?: string;
  block?: string;
  submitted: () => void;
}

export default function InputAlarm(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [type, _type] = useState<any>();
  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useState<any>();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    showLoading();

    Db.createDocument(DATABASE_ID, COLLECTION_ID.blocks, "unique()", {
      name: data.name,
      emoji: props.emoji,
      body: data.body,
      groupId: props.group,
      createdBy: user.$id,
    }).then((blockData) => {
      if (!props.group) return;
      Db.updateDocument(DATABASE_ID, COLLECTION_ID.groups, props.group, {
        lastActivity: moment(),
      }).then(() => {
        dismissLoading();
        props.submitted();
      });
    });
  };

  useEffect(() => {
    User.getAccount().then((data: any) => {
      _user(data);
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Something important and urgent!!!!
          </IonLabel>
          <IonInput
            {...register("name")}
            type="text"
            placeholder="Text goes here"
          />
        </div>
        <div
          style={{
            backgroundColor: "#eee",
            marginTop: 16,
            borderRadius: 8,
            padding: 8,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Give some more details
          </IonLabel>
          <IonTextarea
            {...register("body")}
            rows={6}
            cols={20}
            placeholder="👉 Text goes here "
          />
        </div>
        <IonButton
          type="submit"
          onClick={() => {}}
          style={{ marginTop: 30 }}
          expand="block"
          color="dark"
          shape="round"
        >
          Save
        </IonButton>
      </form>
    </>
  );
}
