import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import User from "../../services/users";
import EmojiSelector from "../EmojiSelector";
import { useForm } from "react-hook-form";
import { contract } from "ionicons/icons";

interface Props {
  emoji: string;
  group?: string;
  block?: string;
  submitted: () => void;
}

export default function InputContact(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [type, _type] = useState<any>();
  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useState<any>();

  const { register, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    showLoading();

    localStorage.setItem("contactInfo", JSON.stringify(data));

    Db.createDocument(DATABASE_ID, COLLECTION_ID.blocks, "unique()", {
      name: data.firstName + " " + data.lastName,
      emoji: props.emoji,
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.lastName,
        email: data.lastName,
        work: data.work,
        mobile: data.mobile,
        location: data.location,
        website: data.website,
      }),
      groupId: props.group,
      createdBy: user.$id,
    }).then((blockData) => {
      if (!props.group) return;
      Db.updateDocument(DATABASE_ID, COLLECTION_ID.groups, props.group, {
        lastActivity: moment(),
      }).then(() => {
        dismissLoading();
        props.submitted();
      });
    });
  };

  useEffect(() => {
    let localJson = localStorage.getItem("contactInfo");
    if (localJson) {
      JSON.parse(localJson);
    }
    User.getAccount().then((data: any) => {
      _user(data);
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            First Name
          </IonLabel>
          <IonInput
            {...register("firstName")}
            type="text"
            placeholder="Your first name"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Last Name
          </IonLabel>
          <IonInput
            {...register("lastName")}
            type="text"
            placeholder="Your last name"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            title
          </IonLabel>
          <IonInput
            {...register("title")}
            type="text"
            placeholder="Your title"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            email
          </IonLabel>
          <IonInput
            {...register("email")}
            type="text"
            placeholder="Your email"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            work
          </IonLabel>
          <IonInput
            {...register("work")}
            type="text"
            placeholder="Your work phone"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            mobile
          </IonLabel>
          <IonInput
            {...register("mobile")}
            type="text"
            placeholder="Your mobile"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            location
          </IonLabel>
          <IonInput
            {...register("location")}
            type="text"
            placeholder="Your location"
          />
        </div>

        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            website
          </IonLabel>
          <IonInput
            {...register("website")}
            type="text"
            placeholder="Your website"
          />
        </div>

        <IonButton
          type="submit"
          onClick={() => {}}
          style={{ marginTop: 30 }}
          expand="block"
          color="dark"
          shape="round"
        >
          Save
        </IonButton>
      </form>
    </>
  );
}
