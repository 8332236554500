import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  useIonLoading,
} from "@ionic/react";
import { Query } from "appwrite";
import * as _ from "lodash";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { userState } from "../services/state";
import GalleryItem from "./GalleryItem";
import ListItem from "./ListItem";
import PhotoGallery from "./PhotoGallery";

interface Props {
  user_id: string;
  showGroupModal: () => void;
}

export default function SavedGroups(props: Props) {
  const [savedItems, _savedItems] = useState<any>();
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();

  useEffect(() => {
    let localSavedItems = localStorage.getItem("savedItems");
    if (localSavedItems) {
      //console.log("localsaved", localSavedItems);
      _savedItems(JSON.parse(localSavedItems));
    }

    setTimeout(async () => {
      console.log("user", props.user_id);
      getdocs(1);
    }, 100);
    setTimeout(async () => {
      if (!savedItems || savedItems.length < 1) {
        getdocs(1);
      }
    }, 1000);
  }, []);

  async function getdocs(page: number) {
    let total = 0;
    let offset = (page - 1) * 100;
    await Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
      Query.equal("userId", props.user_id),
      Query.offset(offset),
      Query.limit(page * 100),
    ]).then(async (data: any) => {
      total = data.total;
      console.log("my groups", data.documents);
      let groupIds: any = [];
      for (let g of data.documents) {
        groupIds.push(g.groupId);
      }
      Db.listDocuments(DATABASE_ID, COLLECTION_ID.groups, [
        Query.equal("isPromoted", true),
        Query.limit(100),
      ]).then(async (data2: any) => {
        for (let g of data2.documents) {
          groupIds.push(g.$id);
        }
        console.log("groupIds", groupIds);
        Db.listDocuments(DATABASE_ID, COLLECTION_ID.groups, [
          Query.equal("$id", groupIds),
          Query.limit(100),
        ]).then((groupData) => {
          localStorage.setItem(
            "savedItems",
            JSON.stringify(groupData.documents)
          );
          console.log("savedItems", groupData);
          _savedItems(groupData.documents);
        });
      });

      return total;
      // dismissLoading()
    });
    return total;
  }

  function SavedList() {
    if (savedItems && savedItems[0]) {
      return savedItems.map((item: any, index: number) => {
        return <ListItem key={index} item={item} />;
      });
    } else {
      return "Loading...";
    }
  }

  return (
    <div style={{ width: "calc(100% )" }}>
      <div
        style={{
          fontSize: 20,
          fontWeight: 900,
          width: "50%",
          display: "inline-block",
        }}
      >
        🎒 My Collection
      </div>
      <div
        style={{
          fontSize: 20,
          fontWeight: 900,
          width: "50%",
          display: "inline-block",
          textAlign: "right",
        }}
      >
        {savedItems && savedItems.length}{" "}
        {savedItems && savedItems.length > 1 && savedItems.length <= 5 && "🐌"}
        {savedItems && savedItems.length > 5 && savedItems.length <= 10 && "🦆"}
        {savedItems &&
          savedItems.length > 10 &&
          savedItems.length <= 20 &&
          "🦉"}
        {savedItems &&
          savedItems.length > 20 &&
          savedItems.length <= 50 &&
          "☃️"}
        {savedItems &&
          savedItems.length > 50 &&
          savedItems.length <= 100 &&
          "🦦"}
        {savedItems &&
          savedItems.length > 100 &&
          savedItems.length <= 200 &&
          "🧞‍♂️"}
        {savedItems &&
          savedItems.length > 200 &&
          savedItems.length <= 400 &&
          "🐙"}
        {savedItems &&
          savedItems.length > 400 &&
          savedItems.length <= 1000 &&
          "🦄"}
        {savedItems && savedItems.length > 1000 && "🦖"}
      </div>
      <div className="lines"></div>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        <IonButton
          size="small"
          color="light"
          expand="block"
          onClick={() => {
            props.showGroupModal();
          }}
        >
          ➕ <div style={{ fontSize: 12 }}> &nbsp;&nbsp;NEW</div>
        </IonButton>
      </div>
      <div style={{ textAlign: "center" }}>
        {props.user_id && savedItems && SavedList()}
      </div>
      <div style={{ height: 200 }}></div>
    </div>
  );
}
