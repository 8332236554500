import { IonAvatar, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useState } from "react";

import { useHistory } from "react-router";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";
import { Avatar, Message } from "@chatscope/chat-ui-kit-react";
import moment from "moment";
import { imageUrl } from "../services/storage";
import ChatDeleteMessage from "./ChatDeleteMessage";

interface Props {
  message: any;
  sender: any;
  user: any;
}

export default function ChatMessage(props: Props) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  return (
    <>
      {visible && (
        <>
          <ChatDeleteMessage
            sender={props.sender}
            message={props.message}
            user={props.user}
            deletedMessage={(message: any) => {
              setVisible(false);
            }}
          />
          <Message
            key={props.message.$id}
            style={{ color: "black", backgroundColor: "white" }}
            model={{
              message: props.message.message,
              direction:
                props.message.userId == props.user.$id
                  ? "outgoing"
                  : "incoming",
              position: 1,
            }}
          >
            <Message.Header
              sender={props.sender.username}
              sentTime={moment(props.message.$createdAt).format("lll")}
            />
            <Avatar
              style={{ border: "2px solid black" }}
              src={
                props.sender &&
                props.sender.imageId &&
                imageUrl(props.sender.imageId)
              }
              onClick={() => {
                if (props.message.userId == props.user.$id) {
                  history.push("/me");
                }
              }}
              name={props.sender.username}
            />
          </Message>
        </>
      )}
    </>
  );
}
