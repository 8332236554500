import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query, Teams } from "appwrite";
import { Emoji } from "emoji-picker-react";
import {
  calendar,
  calendarOutline,
  close,
  closeOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  personOutline,
  saveOutline,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import AllDates from "../components/AllDates";
import NearGroups from "../components/NearGroups";
import MyPeople from "../components/MyPeople";
import MyProfile from "../components/MyProfile";
import PromotedGroups from "../components/PromotedGroups";
import SavedGroups from "../components/SavedGroups";
import UserAccount from "../services/account";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import PWAPrompt from "../services/pwa";
import { accountState, userState, userTeamState } from "../services/state";
import UserTeams from "../services/team";
import GroupModal from "../components/GroupModal";
import { imageUrl } from "../services/storage";
import User from "../services/users";
import StoredImage from "../components/StoredImage";

function Home() {
  const [selectedSegment, _selectedSegment] = useState<any>("mygroups");
  const [showing, _showing] = useState<any>("saved");
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [userTeam, _userTeam] = useRecoilState<any>(userTeamState);
  const [showGroupModal, _showGroupModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    UserAccount.get().then((data) => {
      _account(data);
      Db.getDocument(DATABASE_ID, COLLECTION_ID.users, data.name)
        .then((data: any) => {
          _user(data);
          console.log(data);
          UserTeams.get(data.$id)
            .then((team) => {
              console.log("team", team);
              _userTeam(team);
            })
            .catch((err) => {
              UserTeams.create(data.$id, "user").then((newTeam) => {
                _userTeam(newTeam);
              });
            });
        })
        .catch((err) => {
          Db.createDocument(DATABASE_ID, COLLECTION_ID.users, "unique()", {
            accountId: data.$id,
          }).then((u) => {
            UserAccount.updateName(u.$id);
          });
        });
    });
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div>
          <IonGrid
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              backgroundColor: "white",
              zIndex: 100,
              paddingTop: 18,
              paddingLeft: 18,
            }}
          >
            <IonRow>
              <IonCol
                onClick={() => {
                  showing == "saved" ? _showing("promoted") : _showing("saved");
                }}
              >
                <IonIcon
                  style={{ fontSize: 52, marginBottom: -10 }}
                  icon={serverOutline}
                />
                <div
                  style={{
                    fontSize: 44,
                    letterSpacing: -3.5,
                    fontWeight: 900,
                    display: "inline-block",
                    marginTop: -20,
                  }}
                >
                  TINKAN
                </div>
              </IonCol>
              {selectedSegment != "mygroups" ? (
                <IonCol
                  style={{ textAlign: "right" }}
                  onClick={() => {
                    _selectedSegment("mygroups");
                  }}
                  size="2"
                >
                  <IonIcon
                    style={{ fontSize: 52, marginBottom: -10 }}
                    icon={close}
                  />
                </IonCol>
              ) : (
                <>
                  <IonCol
                    style={{ textAlign: "right" }}
                    onClick={() => {
                      history.push("/me");
                    }}
                    size="2"
                  >
                    <IonAvatar
                      onClick={() => {
                        history.push("/me");
                      }}
                      style={{
                        border: "solid 4px black",
                        backgroundColor: "grey",
                        width: 60,
                        height: 60,
                        position: "fixed",
                        top: 20,
                        right: 20,
                      }}
                    >
                      <StoredImage
                        src={
                          user.imageId
                            ? imageUrl(user.imageId)
                            : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                        }
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          objectPosition: "top",
                        }}
                        alt="item"
                      />
                    </IonAvatar>
                  </IonCol>
                </>
              )}
            </IonRow>
          </IonGrid>
        </div>
        <PWAPrompt />
        <IonGrid>
          <IonRow style={{ paddingTop: 50 }}>
            <IonCol style={{ paddingTop: 20 }}>
              {showing == "near" && (
                <NearGroups
                  name={"mygroups"}
                  showGroupModal={() => {
                    _showGroupModal(true);
                  }}
                />
              )}
              {showing == "saved" && (
                <>
                  {user && user.$id && (
                    <SavedGroups
                      user_id={user.$id}
                      showGroupModal={() => {
                        localStorage.setItem("group_type", "topic");
                        _showGroupModal(true);
                      }}
                    />
                  )}
                </>
              )}
              {showing == "promoted" && (
                <>
                  <NearGroups
                    name={"mygroups"}
                    showGroupModal={() => {
                      _showGroupModal(true);
                    }}
                  />
                  <PromotedGroups
                    showGroupModal={() => {
                      _showGroupModal(true);
                    }}
                  />
                </>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol></IonCol>
          </IonRow>
        </IonGrid>
        {/* {showing == "saved" && (
          <IonFab
            style={{ marginBottom: 10, marginRight: 10 }}
            vertical="bottom"
            horizontal="end"
            slot="fixed"
          >
            <IonFabButton
              style={{ width: "calc(100vw - 40px)", height: 40, maxWidth: 860 }}
              onClick={() => {
                _showing("promoted");
              }}
              color="light"
            >
              <div>
                🛢 <b>PUBLIC TINKANS </b>
              </div>
            </IonFabButton>
          </IonFab>
        )} */}
        {showing != "saved" && (
          <IonFab
            style={{ marginBottom: 10, marginRight: 10 }}
            vertical="bottom"
            horizontal="end"
            slot="fixed"
          >
            <IonFabButton
              style={{
                width: "calc(100vw - 40px)",
                height: 40,
                maxWidth: 860,
              }}
              onClick={() => {
                _showing("saved");
              }}
              color="light"
            >
              <div>
                🛢 <b>MY TINKANS</b>
              </div>
            </IonFabButton>
          </IonFab>
        )}
        {/* <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            height: 60,
            border: "3px solid black",
            borderRadius: 8,
            backgroundColor: "lightgrey",
            overflow: "hidden",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.45)",
          }}
        ></div>
        <div
          style={{
            position: "fixed",
            bottom: 30,

            left: "calc(66% + 2px)",
            display: "inline-block",
            width: "calc(33% - 5px)",
          }}
        >
          <IonButton
            color="light"
            expand="block"
            onClick={() => {
              //_showGroupModal(true);
              _showing("near");
              localStorage.setItem("lastViewed", "near");
            }}
          >
            <div> </div>
            📍<div style={{ fontSize: 12 }}>&nbsp;&nbsp;Places</div>
          </IonButton>
        </div>

        <div
          style={{
            position: "fixed",
            bottom: 30,
            left: 5,
            display: "inline-block",
            width: "calc(33% - 5px)",
          }}
        >
          <IonButton
            color="light"
            expand="block"
            onClick={() => {
              //_showGroupModal(true);
              _showing("promoted");
              localStorage.setItem("lastViewed", "promoted");
            }}
          >
            📢<div style={{ fontSize: 12 }}>&nbsp;&nbsp;Topics</div>
          </IonButton>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 30,
            left: "calc(33% + 3px)",
            display: "inline-block",
            width: "calc(33% - 5px)",
          }}
        >
          <IonButton
            color="light"
            expand="block"
            onClick={() => {
              //_showGroupModal(true);
              _showing("saved");
              localStorage.setItem("lastViewed", "saved");
            }}
          >
            <div style={{ fontSize: 10 }}>
              My <br />
              Collection
            </div>
          </IonButton>
        </div>
        {showing == "near" ? (
          <></>
        ) : (
          <div
            style={{
              position: "fixed",
              bottom: 85,
              left: 8,
              display: "inline-block",
              width: "calc(100% - 14px)",
              zIndex: 100,
            }}
          ></div>
        )} */}
        <GroupModal
          show={showGroupModal}
          dismiss={() => {
            _showGroupModal(false);
          }}
        />
      </IonContent>

      <div className="bottom-bar"></div>
    </IonPage>
  );
}

export default Home;
