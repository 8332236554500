import * as React from "react";
import { useState, useEffect } from "react";
import _ from "lodash";
import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { IonButton } from "@ionic/react";

import { Query } from "appwrite";

import GroupModal from "../components/GroupModal";

interface IParams {}

export default function MapPage(params: IParams) {
  const [loading, _loading] = useState(true);
  const [items, _items] = useState<any>();
  const [theMap, _theMap] = useState<any>();
  const [currentCenter, _currentCenter] = useState<any>();
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100vh",
    latitude: 0,
    longitude: 0,
    zoom: 11,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position.coords.longitude != 0) {
        setViewport({
          ...viewport,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        console.log("position:", position.coords.latitude);
      }
    });
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.groups, [
      Query.equal("isPromoted", false),
    ]).then((data: any) => {
      console.log(data.documents);
      _items(data.documents);
      setTimeout(() => {}, 1000);
    });
  }, []);

  let history = useHistory();

  if (items && items[0] && viewport && viewport.latitude != 0) {
    return (
      <>
        <div style={{ height: "100vh", width: "100%" }}>
          {currentCenter}

          <div
            style={{
              position: "fixed",
              bottom: 35,
              left: 10,
              display: "inline-block",
              width: "calc(100% - 20px)",
            }}
          >
            <IonButton
              size="small"
              color="light"
              expand="block"
              onClick={() => {
                //_showGroupModal(true);
                history.replace("/home");
              }}
            >
              CLOSE
            </IonButton>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        {loading ? (
          <>Loading Map.... </>
        ) : (
          <>
            <h2>Tinkan Requires Locations Turned On</h2>
            <IonButton
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </IonButton>
          </>
        )}
      </div>
    );
  }
}
