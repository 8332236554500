import { IonAvatar, IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import { useState } from "react";

import { useHistory } from "react-router";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";
import { Avatar, Message } from "@chatscope/chat-ui-kit-react";
import moment from "moment";
import { imageUrl } from "../services/storage";
import ChatDeleteMessage from "./ChatDeleteMessage";

interface Props {
  message: any;
  sender: any;
  user: any;
}

export default function ChatGlobalCode(props: Props) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  return (
    <>
      {visible && (
        <>
          {" "}
          <ChatDeleteMessage
            sender={props.sender}
            message={props.message}
            user={props.user}
            deletedMessage={(message: any) => {
              setVisible(false);
            }}
          />
          <div style={{ padding: 10 }}>
            <div style={{ textAlign: "right" }}>
              <IonButton
                href={"https://plus.codes/" + props.message.message}
                target="_blank"
                size="small"
                color="warning"
              >
                🗺 Navigate
              </IonButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
