import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonRow,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import UserAccount from "../services/account";
import User from "../services/users";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { accountState, userState } from "../services/state";
import Db, { BUCKET_ID, COLLECTION_ID, DATABASE_ID } from "../services/db";
import Store, { imageUrl } from "../services/storage";
import StoredImage from "./StoredImage";

interface Props {}

export default function SetupProfile(props: Props) {
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [name, _name] = useState<any>(user?.username);
  const [imageId, _imageId] = useState<string>();

  const history = useHistory();

  return (
    <div style={{ textAlign: "center", paddingTop: 24, width: "100%" }}>
      {user ? (
        <>
          <label className="custom-file-upload-user">
            <input
              accept="image/*"
              capture
              type="file"
              onChange={(e) => {
                let file = e.target.files && e.target.files[0];
                file &&
                  Store.createFile(BUCKET_ID.images, "unique()", file).then(
                    (fileData) => {
                      _imageId(fileData.$id);
                      let u: any = JSON.parse(JSON.stringify(user));
                      u.imageId = fileData.$id;
                      _user(u);

                      Db.updateDocument(
                        DATABASE_ID,
                        COLLECTION_ID.users,
                        user.$id,
                        {
                          imageId: fileData.$id,
                        }
                      );
                    }
                  );
              }}
            />
            {user.imageId ? (
              <StoredImage
                src={imageUrl(user.imageId)}
                style={{ width: "50%", height: "auto" }}
              />
            ) : (
              <StoredImage
                src="https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                style={{ width: "50%", height: "auto" }}
              />
            )}
          </label>
          <h1>{account.email}</h1>

          <div>Username(Public)</div>
          <IonInput
            value={name}
            onIonChange={(e) => {
              _name(e.target.value);
            }}
            className="block-input"
            placeholder="Enter Name"
          ></IonInput>

          <div style={{ paddingTop: 20 }}>
            <IonButton
              onClick={() => {
                Db.updateDocument(DATABASE_ID, COLLECTION_ID.users, user.$id, {
                  username: name,
                }).then(() => {
                  history.push("/home");
                });
              }}
              expand="block"
              shape="round"
              color="dark"
            >
              Save
            </IonButton>
          </div>
        </>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
}
