import { atom, selector, useRecoilState, useRecoilValue } from "recoil";

export const userState = atom({
  key: 'userState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const loginUserState = atom({
  key: 'loginUserState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const accountState = atom({
  key: 'accountState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const membersState = atom({
  key: 'membersState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});


export const userTeamState = atom({
  key: 'userTeamState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const showMeHowState = atom({
  key: 'showMeHow', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

export const recorderState = atom({
  key: 'recorderState', // unique ID (with respect to other atoms/selectors)
  default: null, // default value (aka initial value)
});

export const groupState = atom({
  key: 'groupState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const playlistState = atom({
  key: 'playlistState', // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});


export const playlistStateIndex = atom({
  key: 'playlistStateIndex', // unique ID (with respect to other atoms/selectors)
  default: 0, // default value (aka initial value)
});


export const creditsState = atom({
  key: 'creditsState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const subscriptionState = atom({
  key: 'subscriptionState', // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

