import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import EmojiPicker from "emoji-picker-react";
import {
  close,
  homeOutline,
  personOutline,
  serverOutline,
} from "ionicons/icons";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import SavedGroups from "../components/SavedGroups";
import { accountState, userState, userTeamState } from "../services/state";

function EmojiPage() {
  const [picked, _picked] = useState<any>();

  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <IonPage>
      <IonContent>
        <EmojiPicker
          onEmojiClick={(e) => {
            _picked(e.unified);
            navigator.clipboard.writeText(e.unified);
          }}
        />

        {picked}
      </IonContent>
    </IonPage>
  );
}

export default EmojiPage;
