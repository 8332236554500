import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import User from "../services/users";
import EmojiSelector from "../components/EmojiSelector";
import InputAlarm from "../components/Alarm/InputAlarm";
import InputContact from "../components/Contact/InputContact";
import InputDirections from "../components/Directions/InputDirections";
import InputEvent from "../components/Event/InputEvent";
import InputFact from "../components/Fact/InputFact";
import InputFile from "../components/File/InputFile";
import InputGorilla from "../components/Gorilla/InputGorilla";
import InputLink from "../components/Link/InputLink";
import InputNote from "../components/Note/InputNote";
import InputPoll from "../components/Poll/InputPoll";
import InputQuestion from "../components/Question/InputQuestion";
import InputRemind from "../components/Remind/InputRemind";
import { useHistory, useParams } from "react-router";
import InputImage from "../components/Image/InputImage";
import { useRecoilState } from "recoil";
import { userState } from "../services/state";
import RemotePlugin from "../components/Plugin/RemotePlugin";

interface Props {
  note?: any;
  show?: boolean;
}
interface Params {
  group?: string;
  block?: string;
}

export default function InputPage(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [emoji, _emoji] = useState<any>();
  const [type, _type] = useState<any>();
  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useRecoilState<any>(userState);
  const [group, _group] = useState<any>();
  const [block, _block] = useState<any>();
  const params: Params = useParams();
  const history = useHistory();

  useEffect(() => {
    User.getAccount().then((data: any) => {
      _user(data);
    });

    const url = window.location.href;

    if (params.group) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, params.group).then(
        (groupData) => {
          console.log(groupData);
          _group(groupData);
        }
      );
    }

    if (params.block) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.blocks, params.block).then(
        (blockData) => {
          console.log(blockData);
          _emoji(blockData.emoji);
          _block(blockData);
          _type(blockData.typeId);
        }
      );
    }
  }, []);

  function submitted() {
    history.replace("/group/" + (params.group || block.groupId));
  }

  return (
    <>
      <IonPage>
        <IonContent className="ion-padding">
          <div style={{ position: "fixed", top: 0, left: 0, width: "100%" }}>
            <IonHeader>
              <IonToolbar>
                <IonButtons
                  onClick={() => {
                    history.goBack();
                  }}
                  slot="start"
                >
                  <IonButton>Cancel</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
          </div>
          <>
            <div
              style={{
                paddingLeft: 4,
                marginTop: 56,
                paddingTop: 8,
                paddingBottom: 8,
                width: "calc(100% + 24px)",
                marginLeft: -14,
                textAlign: "center",
              }}
            >
              {!params.block && (
                <>
                  <IonLabel
                    style={{
                      fontWeight: 900,
                      marginTop: 20,
                      marginBottom: 30,
                      fontSize: 32,
                    }}
                    position="stacked"
                  >
                    Power Ups
                  </IonLabel>

                  <EmojiSelector
                    group={group}
                    clicked={(data) => {
                      _emoji(data);
                    }}
                  />
                </>
              )}
            </div>
            <IonGrid class="ion-no-padding">
              <IonRow>
                <IonCol>
                  {type == "plugin" && (
                    <>
                      {window.location.href.includes("block/new") ? null : (
                        <RemotePlugin
                          item={block}
                          user={user}
                          group={group}
                          type="edit"
                        />
                      )}
                    </>
                  )}
                  {/* Remind */}
                  {emoji == "1f9e0" && (
                    <InputRemind
                      group={params.group}
                      block={params.block}
                      emoji="1f9e0"
                      submitted={submitted}
                    />
                  )}

                  {/* Question */}
                  {emoji == "1f91a" && (
                    <InputQuestion
                      group={params.group}
                      block={params.block}
                      emoji="1f91a"
                      submitted={submitted}
                    />
                  )}

                  {/* Message */}
                  {emoji == "1f4ac" && (
                    <InputNote
                      group={params.group}
                      block={params.block}
                      emoji="1f4ac"
                      submitted={submitted}
                    />
                  )}

                  {/* Contact */}
                  {/* TODO: edit */}
                  {emoji == "260e-fe0f" && (
                    <InputContact
                      group={params.group}
                      block={params.block}
                      emoji="260e-fe0f"
                      submitted={submitted}
                    />
                  )}

                  {/* Fact */}
                  {/* TODO: edit */}
                  {emoji == "1f914" && (
                    <InputFact
                      group={params.group}
                      block={params.block}
                      emoji="1f914"
                      submitted={submitted}
                    />
                  )}

                  {/* Event */}
                  {/* TODO: edit */}
                  {emoji == "1f4c5" && (
                    <InputEvent
                      group={params.group}
                      block={params.block}
                      emoji="1f4c5"
                      submitted={submitted}
                    />
                  )}

                  {/* File */}
                  {/* TODO: edit */}
                  {emoji == "1f5c3-fe0f" && (
                    <InputFile
                      group={params.group}
                      block={params.block}
                      emoji="1f5c3-fe0f"
                      submitted={submitted}
                    />
                  )}

                  {/* Alarm */}
                  {emoji == "1f6a8" && (
                    <InputAlarm
                      group={params.group}
                      block={params.block}
                      emoji="1f6a8"
                      submitted={submitted}
                    />
                  )}

                  {/* Link */}
                  {/* TODO: edit */}
                  {emoji == "1f517" && (
                    <InputLink
                      group={params.group}
                      block={params.block}
                      emoji="1f517"
                      submitted={submitted}
                    />
                  )}

                  {/* Poll */}
                  {/* TODO: edit */}
                  {emoji == "1f4ca" && (
                    <InputPoll
                      group={params.group}
                      block={params.block}
                      emoji="1f4ca"
                      submitted={submitted}
                    />
                  )}

                  {/* Directions */}
                  {/* TODO: edit */}
                  {emoji == "1f697" && (
                    <InputDirections
                      group={params.group}
                      block={params.block}
                      emoji="1f697"
                      submitted={submitted}
                    />
                  )}

                  {/* Gorilla */}
                  {/* TODO: edit */}
                  {emoji == "1f98d" && (
                    <InputGorilla
                      group={params.group}
                      block={params.block}
                      emoji="1f98d"
                      submitted={submitted}
                    />
                  )}

                  {/* Image */}
                  {/* TODO: edit */}
                  {(emoji == "1f4f8" || emoji == "1f3a5") && (
                    <InputImage
                      group={params.group}
                      block={params.block}
                      emoji="1f4f8"
                      submitted={submitted}
                    />
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        </IonContent>
      </IonPage>
    </>
  );
}
