import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe("pk_live_51JQaMVACGyibZhfHFInopa7GUbmlucQoQvQwIHWvaQzeqtJqxI3ZlPCMNYKlM0iHvK2taPx3CyOvpUPzEB7bFz3v00IV4i9rrS");
    }
    return stripePromise;
};

export default getStripe;