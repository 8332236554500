import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import {
  close,
  homeOutline,
  personOutline,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import SavedGroups from "../components/SavedGroups";
import { accountState, userState, userTeamState } from "../services/state";

function Saved() {
  const [selectedSegment, _selectedSegment] = useState<any>("mygroups");
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [userTeam, _userTeam] = useRecoilState<any>(userTeamState);

  const history = useHistory();

  useEffect(() => {}, []);

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonIcon
                style={{ fontSize: 52, marginBottom: -10 }}
                icon={serverOutline}
              />
              <div
                style={{
                  fontSize: 44,
                  letterSpacing: -3.5,
                  fontWeight: 900,
                  display: "inline-block",
                  marginTop: -20,
                }}
              >
                SAVED
              </div>
            </IonCol>

            <IonCol
              style={{ textAlign: "right" }}
              onClick={() => {
                history.replace("/home");
              }}
              size="2"
            >
              <IonIcon
                style={{ fontSize: 52, marginBottom: -10 }}
                icon={homeOutline}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol style={{ paddingTop: 20 }}>
              {user && user.$id && (
                <SavedGroups
                  user_id={user.$id}
                  showGroupModal={() => {
                    // TODO
                  }}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default Saved;
