import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from "@ionic/react";
import {
  checkmark,
  checkmarkCircleOutline,
  pencilOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import Db, { COLLECTION_ID, DATABASE_ID, client } from "../services/db";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import * as _ from "lodash";
import StoredImage from "./StoredImage";
import BlockNote from "./Note/BlockNote";
import BlockImage from "./Image/BlockImage";
import { imageUrl } from "../services/storage";
import { emojisSelectUnified } from "./EmojiSelector";
import BlockGorilla from "./Gorilla/BlockGorilla";
import BlockDirections from "./Directions/BlockDirections";
import BlockPoll from "./Poll/BlockPoll";
import BlockLink from "./Link/BlockLink";
import BlockFile from "./File/BlockFile";
import BlockRemind from "./Remind/BlockRemind";
import BlockAlarm from "./Alarm/BlockAlarm";
import BlockEvent from "./Event/BlockEvent";
import BlockFact from "./Fact/BlockFact";
import BlockContact from "./Contact/BlockContact";
import Members from "./Members";
import { useRecoilState } from "recoil";
import { QRCode } from "react-qrcode-logo";
import {
  playlistState,
  playlistStateIndex,
  userState,
} from "../services/state";
import PlayListLightBox from "./Image/LightBox";
import { Button } from "@chatscope/chat-ui-kit-react";
import RemotePlugin from "./Plugin/RemotePlugin";
import { group } from "console";

interface Props {
  group: any;
  name: string;
  members: any;
  edit: () => void;
  showNewNote: () => void;
  isMember: boolean;
}

export default function Notes(props: Props) {
  const [blocks, _blocks] = useState<any>();
  const [totalBlocks, _totalBlocks] = useState<any>();
  const [type, _type] = useState("all");
  const [membership, _membership] = useState<any>();
  const [filters, _filters] = useState<any>();
  const [selectedFilter, _selectedFilter] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);
  const [orderBy, _orderBy] = useState("$createdAt");
  const [playlist, _playlist] = useRecoilState<any>(playlistState);
  const [playlistIndex, _playlistIndex] = useRecoilState(playlistStateIndex);
  const [playlistOpen, _playlistOpen] = useState(false);
  const [canLightBox, _canLightBox] = useState(false);
  const [isPublic, _isPublic] = useState(true);
  const [isPlaying, _isPlaying] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let groupId = props.group.$id;
    console.log(props.group);
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.blocks, [
      Query.equal("groupId", groupId),
      Query.orderDesc("$createdAt"),
      Query.limit(100),
      Query.offset(0),
    ]).then((data: any) => {
      _totalBlocks(data.total);
      _blocks(data.documents);
      _filters(_.uniqBy(data.documents, "emoji"));

      _playlist(data.documents);
      setTimeout(() => {
        _canLightBox(true);
      }, 1000);
    });

    let url = window.location.href;
    if (url.includes("/t/")) {
      _isPublic(true);
    } else {
      _isPublic(false);
    }

    // let unSub = client.subscribe(
    //   "databases.tinkan.collections.groups.documents." + props.group.$id,
    //   (response) => {
    //     // Callback will be executed on changes for all files.
    //     if (props.group.useOrderId) {
    //       _orderBy("orderId");
    //     }
    //     Db.listDocuments(DATABASE_ID, COLLECTION_ID.blocks, [
    //       Query.equal("groupId", groupId),
    //       Query.orderDesc("$createdAt"),
    //       Query.limit(100),
    //       Query.offset(0),
    //     ]).then((data: any) => {
    //       _totalBlocks(data.total);

    //       _blocks(data.documents);
    //       _filters(_.uniqBy(data.documents, "emoji"));
    //       _playlist(data.documents);
    //     });
    //   }
    // );
    if (!isPublic) {
      if (!user || !user.$id) {
        console.log("no user");
        if (localStorage.getItem("user")) {
          let localUser = JSON.parse(localStorage.getItem("user") || "{}");
          _user(localUser);
          console.log("localUser", localUser);

          if (localUser) {
            Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
              Query.equal("groupId", groupId),
              Query.equal("userId", localUser.$id),
            ]).then((res) => {
              let groupMember = res.documents[0];
              _membership(groupMember);
              console.log("membership", res.documents[0]);
            });
          }
        }
      } else {
        Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
          Query.equal("groupId", groupId),
          Query.equal("userId", user.$id),
        ]).then((res) => {
          console.log("membership user.$id", user.$id);
          _membership(res.documents[0]);
          console.log("membership", res.documents[0]);
        });
      }
    }
    return () => {
      // unSub();
      console.log("unsub");

      // actions to be performed when component unmounts
    };
  }, []);

  function Item(itemProps: any) {
    const [showDetails, _showDetails] = useState(true);

    let emoji =
      itemProps.item && itemProps.item.emoji ? itemProps.item.emoji : null;

    let type =
      itemProps.item && itemProps.item.typeId ? itemProps.item.typeId : null;

    return (
      <>
        {type == "plugin" && (
          <>
            <RemotePlugin
              item={itemProps.item}
              user={user}
              group={props.group}
              type="block"
            />
          </>
        )}
        {/* Image */}
        {(emoji == "1f4f8" || emoji == "1f3a5") && (
          <BlockImage
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
            clicked={() => {
              if (isPublic) {
                history.push(
                  "/t/" +
                    itemProps.item.groupId +
                    "/block/" +
                    itemProps.item.$id
                );
              } else {
                history.push(
                  "/group/" +
                    itemProps.item.groupId +
                    "/block/" +
                    itemProps.item.$id
                );
              }
            }}
            clickedPlay={() => {
              if (isPublic) {
                history.push(
                  "/t/" +
                    itemProps.item.groupId +
                    "/block/" +
                    itemProps.item.$id
                );
              } else {
                history.push(
                  "/group/" +
                    itemProps.item.groupId +
                    "/block/" +
                    itemProps.item.$id
                );
              }
            }}
          />
        )}

        {/* Remind */}
        {emoji == "1f9e0" && (
          <BlockRemind
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Question */}
        {emoji == "1f91a" && (
          <BlockNote
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}
        {/* Message */}
        {emoji == "1f4ac" && (
          <BlockNote
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Contact */}
        {emoji == "260e-fe0f" && (
          <BlockContact
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Fact */}
        {emoji == "1f914" && (
          <BlockFact
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
            isPublic={isPublic}
          />
        )}

        {/* Event */}
        {emoji == "1f4c5" && (
          <BlockEvent
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* File */}
        {emoji == "1f5c3-fe0f" && (
          <BlockFile
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Alarm */}
        {emoji == "1f6a8" && (
          <BlockAlarm
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Link */}
        {emoji == "1f517" && (
          <BlockLink
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Poll */}
        {emoji == "1f4ca" && (
          <BlockPoll
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Directions */}
        {emoji == "1f697" && (
          <BlockDirections
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}

        {/* Gorilla */}
        {emoji == "1f98d" && (
          <BlockGorilla
            item={itemProps.item}
            emoji={emoji}
            showDetails={showDetails}
          />
        )}
      </>
    );
  }

  function ListFilters() {
    return (
      filters &&
      filters
        .filter((x: any) => x.emoji)
        .map((item: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                display: "inline-block",
                verticalAlign: "top",
                textAlign: "center",
                width: 70,
              }}
            >
              <div style={{ marginLeft: 8 }}>
                <IonFabButton
                  onClick={() => {
                    _selectedFilter(item.emoji ? item.emoji : null);
                  }}
                  color="light"
                >
                  {item && item.emoji && <Emoji unified={item.emoji} />}
                </IonFabButton>
              </div>
              <div style={{ fontWeight: "bold", fontSize: 12, paddingTop: 3 }}>
                {item && item.emoji && emojisSelectUnified(item.emoji)?.label}
              </div>
            </div>
          );
        })
    );
  }

  return (
    <div
      style={{
        width: "calc(100% - 4px)",
        marginTop: 60,
      }}
    >
      {/* <div
        style={{
          zIndex: 9,
          paddingTop: 0,
          position: "sticky",
          top: 65,
          left: 0,
          paddingBottom: 0,
          backgroundColor: "white",
          marginLeft: -22,
          width: "calc(100% + 48px)",
        }}
      >
        <div>
          <div
            style={{
              marginTop: 8,
              paddingTop: 8,
              paddingBottom: 8,
              backgroundColor: "#eee",
              width: "100%",
              overflowX: "auto",
              whiteSpace: "nowrap",
              borderBottom: "4px solid black",
              borderTop: "4px solid black",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
            }}
          >
            <div
              style={{
                marginLeft: 16,
                marginRight: 8,
                paddingLeft: 20,
                paddingRight: 8,
                display: "inline-block",
                verticalAlign: "top",
                textAlign: "center",
              }}
            >
              <IonFabButton
                onClick={() => {
                  _selectedFilter(undefined);
                }}
                color="light"
              >
                <Emoji unified="1f3e0" />
              </IonFabButton>
              <div style={{ fontWeight: "bold", fontSize: 12, paddingTop: 3 }}>
                All
              </div>
            </div>

            <ListFilters />

            <div style={{ width: 100, display: "inline-block" }}> &nbsp;</div>
          </div>
        </div>
      </div> */}
      {props.group && props.group.imageId ? (
        // <StoredImage
        //   id={item?.imageId}
        //   style={{
        //     marginTop: 100,
        //     width: "100%",
        //     height: 200,
        //     borderRadius: 16,
        //   }}
        // />
        <>
          <div style={{ textAlign: "center" }}>
            {/* <div
                style={{
                  marginLeft: 5,
                  marginTop: 0,
                  marginBottom: 20,
                  height: "30%",
                  width: "30%",
                  overflow: "hidden",
                  boxShadow: "-2px 4px 0px 0px #005e5a",
                  border: "4px solid black",
                  borderRadius: 10,
                  marginRight: 10,
                  display: "inline-block",
                  verticalAlign: "top",
                  backgroundSize: "cover",
                  backgroundImage: `url(${imageUrl(
                    props.group.imageId,
                    true,
                    20,
                    1000
                  )})`,
                }}
              ></div> */}
          </div>
        </>
      ) : (
        <>
          <div style={{ height: 0 }}></div>
        </>
      )}
      <div
        style={{
          paddingLeft: 5,
          paddingRight: 5,
          textAlign: "center",
          paddingTop: 5,
          paddingBottom: 5,
          borderRadius: 0,
          marginBottom: 30,
          border: "0px solid black",
          borderBottom: "4px solid black",
        }}
      >
        {props.group?.description}
      </div>
      {/* <div style={{ marginBottom: 7, marginTop: 5 }}>
        <Members groupId={props.group.$id} />
      </div> */}
      {/* switch for alerts */}

      {/* {blocks && membership && props.group.$id && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              marginRight: 4,
              marginBottom: 10,
              marginTop: 1,
              display: "inline-block",
            }}
          >
            <IonToggle
              checked={membership.alerts}
              onIonChange={(e) => {
                //setAlert(e.detail.checked);
                if (e.detail.checked) {
                  console.log("checked", e.detail.checked);

                  console.log(membership);
                  Db.updateDocument(DATABASE_ID, COLLECTION_ID.groupMembers, membership.$id, {
                    alerts: true,
                  });
                } else {
                  console.log("unchecked");
                  // setAlert(false);
                  // console.log("alert is now false");
                  // console.log(alert);
                  Db.updateDocument(DATABASE_ID, COLLECTION_ID.groupMembers, membership.$id, {
                    alerts: false,
                  });
                }
              }}
            />
          </div>
          <div
            style={{
              marginTop: 0,
              display: "inline-block",
              verticalAlign: "top",
              fontSize: 24,
            }}
          >
            📳
          </div>
        </div>
      )} */}
      <div style={{ textAlign: "center" }}>
        {canLightBox && playlist && playlist.length > 0 && (
          <PlayListLightBox
            groupId={props.group.$id}
            user={user}
            playlist={playlist}
            index={playlistIndex}
            setOpen={(open) => {
              _isPlaying(false);
              _playlistOpen(open);
            }}
            open={playlistOpen}
            isPlaying={isPlaying}
          />
        )}
      </div>
      {blocks &&
        _.orderBy(blocks, "orderId", "desc")
          .filter((x) => x.emoji)
          .filter((x: any) => {
            return x.emoji && selectedFilter && selectedFilter != x.emoji
              ? false
              : true;
          })
          .map((item: any, index: number) => {
            return <Item key={index} item={item} />;
          })}
      {blocks && !blocks[0] && (
        <div style={{ fontSize: 34, textAlign: "center", marginTop: 30 }}>
          <StoredImage src="/assets/empty.gif" style={{ width: 200 }} />
          <br />
          Sorry, nothing yet come back later!!!
        </div>
      )}
      <div
        style={{
          textAlign: "center",
          paddingTop: 30,
          marginBottom: 10,
        }}
      >
        <QRCode
          qrStyle="dots"
          eyeRadius={10}
          size={100}
          value={"https://a.tinkan.app/group/" + props.group.$id}
        />
        <br />
        Scan Group
      </div>
      <div style={{ height: 80 }}></div>
      {props.isMember && (
        <div
          style={{
            width: "100%",
            fontSize: 12,
            marginBottom: 10,
            textAlign: "center",
          }}
        >
          I no longer want updates
          <div
            style={{
              marginTop: 10,
              marginLeft: "auto",
              marginRight: "auto",
              width: "fit-content",
              textAlign: "center",
              backgroundColor: "orange",
              color: "white",
              padding: 6,
              paddingLeft: 20,
              paddingRight: 20,
              borderRadius: 10,
              marginBottom: 10,
              fontWeight: "bold",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
            }}
            onClick={() => {
              alert("Leave group not built yet. Email jon@t3ch.net to leave.");
            }}
          >
            Leave The Group
          </div>
        </div>
      )}
      <div style={{ height: 80 }}></div>
    </div>
  );
}
