import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendar,
  calendarOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  server,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import UserAccount from "../services/account";
import { loginUserState, userState } from "../services/state";
import User from "../services/users";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Login() {
  const [selectedSegment, _selectedSegment] = useState<any>("alldates");
  const [phone, _phone] = useState<any>();
  const [password, _password] = useState<any>();
  const [errorMessage, _errorMessage] = useState<any>();
  const [skippedHomeScreen, _skippedHomeScreen] = useState(false);

  const ref = useRef<any>();

  const history = useHistory();

  const [user, _user] = useRecoilState<any>(userState);

  const [loginUser, _loginUser] = useRecoilState<any>(loginUserState);

  function isRunningStandalone() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  useEffect(() => {
    // get current url
    let url = window.location.href;

    if (!url.includes("/login")) {
      console.log("after_login", url);
      localStorage.setItem("after_login", url);
    }
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div style={{ textAlign: "center" }}>
          <div style={{ fontWeight: 900, fontSize: 80, marginTop: 50 }}>
            <IonIcon icon={serverOutline} />
          </div>
          <div style={{ fontWeight: 900, fontSize: 40 }}>TINKAN</div>

          <div
            onClick={() => {
              if (ref) {
                ref.current?.focus();
              }
            }}
          >
            <br />
            We just need to make sure your are a real person!
            <br />
            <h3>Enter Phone #</h3>
            <div style={{}}>
              <PhoneInput
                country={"us"}
                inputStyle={{ width: "100%", fontSize: 38, height: 48 }}
                value={phone}
                onChange={(e) => _phone(e)}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                showDropdown={false}
              />
            </div>
            {phone}
            <div style={{ color: "red" }}>{errorMessage}</div>
            <br />
            <br />
            <IonButton
              disabled={
                !phone || (phone && phone.substring(0, 10).length != 10)
              }
              expand="block"
              onClick={() => {
                let x = phone;
                // if first number is 1, remove it
                if (x.substring(0, 1) == "1") {
                  x = x.substring(1);
                }
                UserAccount.createPhoneSession(
                  "unique()",
                  "+1" + x.substring(0, 10)
                ).then(
                  (data) => {
                    _loginUser(data.userId);
                    history.replace("/code");
                    console.log("data", data);
                  },
                  (error) => {
                    console.log("error", error);
                  }
                );
              }}
            >
              Get Code
            </IonButton>
          </div>

          {/* <div style={{ marginTop: 50, textAlign: "center" }}>
              Tinkan is better on your home screen.
              <br />
              <br />
              <br /> on iPhone do this!
              <br />
              <StoredImage
                style={{ marginBottom: 0 }}
                src="/assets/addtohomeIOS.jpg"
                width="100%"
              />{" "}
              <br />
              <br />
              <br /> on Android do this!
              <br />
              <StoredImage
                style={{ marginBottom: 0 }}
                src="/assets/addtohomeAndroid.jpeg"
                width="50%"
              />
              <IonButton
                color="light"
                shape="round"
                size="small"
                expand="block"
                style={{ marginTop: 50 }}
                onClick={() => {
                  _skippedHomeScreen(true);
                }}
              >
                I don't care, skip for now
              </IonButton>
            </div> */}
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 5,
            left: 5,
            backgroundColor: "white",
            zIndex: 100,
          }}
        >
          <a href="/tos.html">Terms of Use</a>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Login;
