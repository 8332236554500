import { Client, Functions } from "appwrite";
import { HOST_URI, PROJECT_ID } from "./db";


const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

export const appFunction = new Functions(client);

