import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonToast,
  useIonLoading,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import {
  backspaceOutline,
  cameraOutline,
  cameraReverseOutline,
  closeOutline,
  cloudUploadOutline,
  cogOutline,
  flameOutline,
  homeOutline,
  peopleOutline,
  saveOutline,
  shareSocialOutline,
} from "ionicons/icons";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useRecoilState } from "recoil";
import NoteModal from "./InputPage";
import Notes from "../components/Notes";
import People from "../components/People";
import UserAccount, { UserCanEdit } from "../services/account";
import Db, {
  BUCKET_ID,
  COLLECTION_ID,
  DATABASE_ID,
  client,
} from "../services/db";
import {
  accountState,
  creditsState,
  groupState,
  membersState,
  showMeHowState,
  subscriptionState,
  userState,
} from "../services/state";
import Store, { imageUrl } from "../services/storage";
import Marquee from "react-fast-marquee";
import moment from "moment";
import { appFunction } from "../services/function";

function Group() {
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [members, _members] = useRecoilState<any>(membersState);
  const [showMeHow, _showMeHow] = useRecoilState(showMeHowState);
  const [selectedSegment, _selectedSegment] = useState<any>("brief");
  const [showEditor, _showEditor] = useState(false);
  const [isMember, _isMember] = useState<any>();
  const [group, _group] = useRecoilState(groupState);
  const [selectedNote, _selectedNote] = useState<any>();
  const [item, _item] = useState<any>();
  const [unsubscribe, _unsubscribe] = useState<any>();
  const [canEdit, _canEdit] = useState(false);
  const [uploadedFile, _uploadedFile] = useState<any>();
  const [credits, _credits] = useRecoilState<any>(creditsState);
  const [subscription, _subscription] = useRecoilState<any>(subscriptionState);
  const { id } = useParams<any>();
  const history = useHistory();
  const [showToast, _showToast] = useState(false);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [isPublic, setIsPublic] = useState(false);

  const [imageCover, _imageCover] = useState(true);

  // get query params for link
  const query = new URLSearchParams(useLocation().search);
  const link = query.get("link");

  const location = useLocation();

  function scrollToBottom() {
    let content: any = window.document.querySelector("#group-content");
    return content && content.scrollToBottom();
  }

  function isOwner() {
    if (user && item && user.$id == item.createdBy) {
      return true;
    } else {
      return false;
    }
  }

  function getCredits(u: any) {
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.credits, [
      Query.equal("userId", u.accountId),
    ]).then((c: any) => {
      console.log(COLLECTION_ID.credits, c);
      _subscription(c.documents[0]);
      if (c.total != 0) {
        _credits(c.documents[0].credits);
      }
    });
  }

  useEffect(() => {
    dismissLoading();

    // get url
    let url = window.location.href;
    console.log("url", url);
    if (url.includes("/t/")) {
      setIsPublic(true);
    }

    // presentLoading({
    //   message: `“If you can't yet do great things, do small things in a great way.” ―Napoleon Hill`,
    //   duration: 2000,
    //   spinner: "circles",
    // });

    UserAccount.get().then((a) => {
      _account(a);

      Db.getDocument(DATABASE_ID, COLLECTION_ID.users, a.name).then(
        (u: any) => {
          _user(u);

          getCredits(u);

          console.log("user", u);
          Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
            Query.equal("groupId", id),
          ]).then((m: any) => {
            _members(m.documents);
            console.log("members", m);
            let find = _.findIndex(m.documents, { userId: u.$id });
            if (find >= 0) {
              console.log("_isMember", true);
              _isMember(true);
            } else {
              console.log("_isMember", false);
              _isMember(false);
            }
          });
        }
      );
    });

    Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, id).then((data) => {
      _item(data);
      _group(data);

      setTimeout(() => {
        UserCanEdit(data).then((x) => {
          console.log("canEdit", x);
          _canEdit(x);
        });
      }, 400);
    });

    console.log("Entered Group");
    // databases.[ID].collections.[ID].documents.[ID]
  }, []);

  function membersList() {
    if (members) {
      return members.map((m: any) => {
        return m.userId;
      });
    }
  }

  return (
    <IonPage>
      {selectedSegment == "brief" && (
        <>
          {!isPublic && (
            <IonFab
              style={{ marginBottom: 90, marginRight: 0 }}
              vertical="bottom"
              horizontal="end"
              slot="fixed"
            >
              <IonFabButton
                size="small"
                onClick={() => {
                  history.push("/group/" + item.$id + "/block/new");
                }}
                color="light"
              >
                <Emoji unified="2795" />
              </IonFabButton>
            </IonFab>
          )}
        </>
      )}
      <IonContent className="ion-padding">
        {navigator.share && (
          <IonFab
            style={{ marginBottom: 10, marginLeft: 0 }}
            vertical="bottom"
            horizontal="start"
            slot="fixed"
          >
            <IonFabButton
              size="small"
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: item.name + " -  TINKAN 🛢",
                      text: 'Your invited to "' + item.name + '" on TINKAN 🛢',
                      url:
                        "https://a.tinkan.app/group/" + item.$id + "?link=true",
                    })
                    .then(() => console.log("Successful share"))
                    .catch((error) => console.log("Error sharing", error));
                }
              }}
              color="light"
            >
              <IonIcon icon={shareSocialOutline} />
            </IonFabButton>
          </IonFab>
        )}
        <IonGrid class="ion-no-padding">
          <IonRow>
            <IonCol size="12">
              <IonGrid>
                <IonRow>
                  <IonCol style={{ textAlign: "left" }}>
                    <div
                      style={{
                        position: "fixed",
                        backgroundColor: "white",
                        padding: "24px 24px 0px 24px",
                        width: "100%",
                        top: 0,
                        left: 0,
                        zIndex: 9,
                      }}
                    >
                      {!isPublic && (
                        <div
                          style={{ display: "inline-block" }}
                          onClick={() => {
                            history.replace("/home");
                          }}
                        >
                          <IonIcon
                            style={{ fontSize: 52, marginBottom: -10 }}
                            icon={backspaceOutline}
                          />
                        </div>
                      )}

                      <div
                        style={{
                          fontSize: 24,
                          letterSpacing: -2,
                          fontWeight: 900,
                          display: "inline-block",
                          marginTop: 0,
                          width: isPublic ? "100%" : "60%",
                          textAlign: isPublic ? "center" : "left",
                        }}
                      >
                        {selectedSegment != "brief"
                          ? selectedSegment.toUpperCase()
                          : item?.name}
                      </div>
                      {selectedSegment == "brief" ? (
                        <>
                          {!isPublic && (
                            <>
                              {isMember == true && (
                                <>
                                  {isOwner() == true ? (
                                    <div
                                      style={{
                                        fontSize: 42,
                                        letterSpacing: -3.5,
                                        fontWeight: 900,
                                        position: "fixed",
                                        top: 40,
                                        right: 20,
                                        marginTop: -20,
                                        textAlign: "right",
                                      }}
                                      onClick={() => {
                                        _selectedSegment("crowd");
                                      }}
                                    >
                                      <IonIcon
                                        style={{
                                          marginBottom: -10,
                                        }}
                                        icon={peopleOutline}
                                      />
                                    </div>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          fontSize: 42,
                                          letterSpacing: -3.5,
                                          fontWeight: 900,
                                          position: "fixed",
                                          top: 40,
                                          right: 20,
                                          marginTop: -20,
                                          textAlign: "right",
                                        }}
                                        onClick={() => {
                                          _selectedSegment("crowd");
                                        }}
                                      >
                                        <IonIcon
                                          style={{
                                            marginBottom: -10,
                                          }}
                                          icon={peopleOutline}
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {isMember == false && (
                                <>
                                  <div
                                    style={{
                                      fontSize: 32,
                                      letterSpacing: -3.5,
                                      fontWeight: 900,
                                      display: "inline-block",
                                      marginTop: -20,
                                      width: "calc(35% - 55px)",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item.rep || 0}
                                    <IonIcon
                                      style={{
                                        verticalAlign: "top",
                                        fontSize: 22,
                                      }}
                                      icon={flameOutline}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      position: "fixed",
                                      bottom: 20,
                                      left: 10,
                                      fontSize: 52,
                                      letterSpacing: -3.5,
                                      fontWeight: 900,
                                      display: "inline-block",
                                      marginTop: -20,
                                      width: "calc(100% - 20px)",
                                      textAlign: "right",
                                    }}
                                    onClick={() => {
                                      Db.createDocument(
                                        DATABASE_ID,
                                        COLLECTION_ID.groupMembers,
                                        "unique()",
                                        {
                                          groupId: id,
                                          userId: user.$id,
                                        }
                                      ).then((um) => {
                                        Db.updateDocument(
                                          DATABASE_ID,
                                          COLLECTION_ID.groups,
                                          id,
                                          {
                                            rep: item.rep + 1,
                                          }
                                        );
                                        _isMember(true);
                                        let newMembers = members;
                                        newMembers.push(um);
                                        _members(newMembers);
                                      });
                                    }}
                                  >
                                    <IonButton
                                      size="large"
                                      color={"light"}
                                      expand="block"
                                    >
                                      JOIN THIS TINKAN 🛢
                                    </IonButton>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            fontSize: 52,
                            fontWeight: 900,
                            position: "fixed",
                            top: 15,
                            right: 20,
                            textAlign: "right",
                          }}
                          onClick={() => {
                            _selectedSegment("brief");
                          }}
                        >
                          <IonIcon
                            style={{
                              marginBottom: -10,
                            }}
                            icon={closeOutline}
                          />
                        </div>
                      )}
                      {/* <div
                        style={{
                          fontSize: 20,
                          marginBottom: 12,
                          fontWeight: 900,
                          marginLeft: -24,
                          marginRight: -24,
                        }}
                      >
                        <Marquee speed={30} delay={4} loop={1} gradient={false}>
                          {item && item.emoji && (
                            <Emoji unified={item?.emoji} size={24} />
                          )}
                          &nbsp; &nbsp;
                          {item?.name} &nbsp; &nbsp; &nbsp; &nbsp;
                        </Marquee>
                      </div> */}
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ textAlign: "center" }}></IonCol>
                </IonRow>
                <IonRow>
                  {selectedSegment == "brief" && item && members && (
                    <Notes
                      members={members}
                      group={item}
                      edit={() => {
                        _showEditor(true);
                      }}
                      name={"brief"}
                      showNewNote={() => {
                        _showEditor(true);
                      }}
                      isMember={isMember}
                    />
                  )}
                  {selectedSegment == "crowd" && item && (
                    <People
                      group={item}
                      name={"crowd"}
                      updateGroup={() => {
                        Db.getDocument(
                          DATABASE_ID,
                          COLLECTION_ID.groups,
                          id
                        ).then((data) => {
                          _item(data);
                          _group(data);
                        });
                      }}
                    />
                  )}
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {false && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              backgroundColor: "#00eeeedd",
            }}
            onClick={() => {
              _showMeHow(false);
            }}
          ></div>
          <div
            style={{
              zIndex: 999,
              position: "fixed",
              bottom: 110,
              width: 200,
              backgroundColor: "white",
              right: "calc(50% - 98px)",
              fontWeight: 500,
              fontSize: 18,
              textAlign: "center",
              padding: 10,
              borderRadius: 10,
              border: "3px solid black",
              boxShadow: "-2px 4px 0px 0px #005e5a",
            }}
            onClick={() => {
              _showMeHow(false);
            }}
          >
            <h1> now add your own story</h1>
            click on upload
            <br />
            ⬇️
          </div>
        </>
      )}
      {isMember && selectedSegment == "brief" && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            padding: 10,
            zIndex: 999,
          }}
        >
          <div
            className="bottom-camera"
            style={{
              position: "absolute",
              right: 18,
              bottom: 26,
              textAlign: "right",
            }}
          >
            <div
              onClick={() => {
                _showMeHow(false);
              }}
            >
              {(isOwner() == true || item.isPrivate != true) && !isPublic && (
                <label className="custom-file-upload">
                  <input
                    multiple={false}
                    accept="image/*, video/*"
                    type="file"
                    onChange={(e) => {
                      if (credits && credits < 0) {
                        history.push("/credits");
                        return;
                      }
                      if (e.target.files && e.target.files[1]) {
                        alert("Oops!! 👀 The limit is 10 images at a time.");
                        _showToast(false);
                        return;
                      }

                      let bucket = BUCKET_ID.images;
                      let isVideo = false;
                      if (
                        e.target.files &&
                        e.target.files[0].type.includes("video")
                      ) {
                        bucket = BUCKET_ID.video;
                        isVideo = true;
                      }

                      _showToast(true);
                      let afile = e.target.files && e.target.files[0];
                      if (
                        e.target.files &&
                        e.target.files[0] &&
                        e.target.files[1]
                      ) {
                        for (let i = 0; i < e.target.files.length; i++) {
                          Store.createFile(
                            bucket,
                            "unique()",
                            e.target.files[i]
                          ).then((fileData) => {
                            Db.createDocument(
                              DATABASE_ID,
                              COLLECTION_ID.blocks,
                              "unique()",
                              {
                                imageId: fileData.$id,
                                emoji: isVideo ? "1f3a5" : "1f4f8",
                                groupId: id,
                                createdBy: account.$id,
                              }
                            ).then((newBlockData) => {
                              // alert("👍 Image uploaded");
                            });
                          });
                        }
                      } else if (e.target.files && e.target.files[0]) {
                        Store.createFile(
                          bucket,
                          "unique()",
                          e.target.files[0]
                        ).then((fileData) => {
                          Db.createDocument(
                            DATABASE_ID,
                            COLLECTION_ID.blocks,
                            "unique()",
                            {
                              imageId: fileData.$id,
                              emoji: isVideo ? "1f3a5" : "1f4f8",
                              groupId: id,
                              createdBy: account.$id,
                            }
                          ).then((newBlockData) => {
                            _showToast(false);
                            history.replace("/block/edit/" + newBlockData.$id);
                          });
                        });
                      }
                    }}
                  />
                  <IonIcon style={{ fontSize: 54 }} icon={cameraOutline} />
                </label>
              )}
            </div>
            <IonToast
              isOpen={showToast}
              position="middle"
              onDidDismiss={() => _showToast(false)}
              message="🙌 Uploading..."
              duration={10000}
            />
          </div>
          {showToast && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                background: "#66CAD2",
                height: "100vh",
                width: "100%",
                zIndex: 999,
              }}
            ></div>
          )}
        </div>
      )}

      {/* {item && (
        <NoteModal
          group={item}
          note={selectedNote}
          show={showEditor}
          // dismiss={() => {
          //   _showEditor(false);
          // }}
        />
      )} */}

      {!account ||
        (!account.$id && (
          <div
            style={{
              position: "fixed",
              bottom: 20,
              left: 10,
              fontSize: 52,
              letterSpacing: -3.5,
              fontWeight: 900,
              display: "inline-block",
              marginTop: -20,
              width: "calc(100% - 20px)",
              textAlign: "right",
            }}
            onClick={() => {
              let url = window.location.href;
              localStorage.setItem("after_login", url);
              history.push("/login");
            }}
          >
            <IonButton size="large" color={"light"} expand="block">
              JOIN IN 🛢
            </IonButton>
          </div>
        ))}
    </IonPage>
  );
}

export default Group;
