import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonToast,
  RefresherEventDetail,
  useIonLoading,
} from "@ionic/react";
import { Permission, Query, Role } from "appwrite";
import { chevronDownCircle, chevronDownCircleOutline } from "ionicons/icons";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { userState } from "../services/state";
import UserTeams from "../services/team";
import GroupModal from "./GroupModal";
import ListItem from "./ListItem";
import * as turf from "@turf/turf";
import StoredImage from "./StoredImage";

interface Props {
  name: string;
  showGroupModal: () => void;
}

export default function NearGroups(props: Props) {
  const [user, _user] = useRecoilState<any>(userState);

  const [items, _items] = useState<any>();

  const [locationError, _locationError] = useState<any>();
  const [showGroupModal, _showGroupModal] = useState(false);
  const [showToast, _showToast] = useState(false);

  const [presentLoading, dismissLoading] = useIonLoading();

  const history = useHistory();

  function distance(from: any, to: any) {
    var point1 = turf.point([from.lon, from.lat]);
    var point2 = turf.point([to.lon, to.lat]);
    var options: any = { units: "miles" };

    return turf.distance(point1, point2, options);
  }

  function getBoundsFromLatLng(lat: any, lng: any, radiusInKm: number) {
    const p = turf.point([lng, lat]);
    let buffer = turf.buffer(p, radiusInKm, { units: "miles" });
    let bbox = turf.bbox(buffer);
    let poly: any = turf.bboxPolygon(bbox);

    return {
      lon_max: poly.bbox[2],
      lon_min: poly.bbox[0],
      lat_max: poly.bbox[3],
      lat_min: poly.bbox[1],
    };
  }

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    event.detail.complete();

    console.log("Begin async operation");
    _showToast(true);
    getNearKans();
  }

  function getNearKans() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let bounds = getBoundsFromLatLng(
          position.coords.latitude,
          position.coords.longitude,
          50
        );
        console.log("bounds", bounds);
        console.log(
          "here",
          position.coords.latitude,
          position.coords.longitude
        );

        if (position.coords.longitude && position.coords.latitude) {
          Db.listDocuments(DATABASE_ID, COLLECTION_ID.groups, [
            Query.equal("isPromoted", true),
            Query.lessThanEqual("lon", bounds.lon_max),
            Query.greaterThanEqual("lon", bounds.lon_min),
            Query.lessThanEqual("lat", bounds.lat_max),
            Query.greaterThanEqual("lat", bounds.lat_min),
          ]).then((data: any) => {
            // console.log(data.documents);

            let groups = [];
            for (let g of data.documents) {
              let from = { lat: position.coords.latitude, lon: g.lon };
              let to = { lat: g.lat, lon: position.coords.longitude };
              let distanceFrom = distance(from, to);
              g.distanceFrom = distanceFrom;
              if (distanceFrom <= (g.distance ? g.distance : 1)) {
                groups.push(g);
              }
            }
            _items(groups);
            localStorage.setItem("near", JSON.stringify(groups));
            console.log(COLLECTION_ID.groups, groups);
          });
        }
      },
      (error) => {
        console.log(error);
        _locationError(error.message);
      }
    );
  }

  useEffect(() => {
    let localItems = localStorage.getItem("near");
    if (localItems) {
      _items(JSON.parse(localItems));
    }
    getNearKans();
  }, []);

  function List() {
    return (
      items &&
      items[0] &&
      items.map((item: any, index: number) => {
        return <ListItem key={index} item={item} />;
      })
    );
  }

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent
          style={{ display: "none" }}
          pullingIcon={chevronDownCircleOutline}
          pullingText="Pull to refresh"
          refreshingSpinner="circles"
          refreshingText="Refreshing..."
        ></IonRefresherContent>
      </IonRefresher>
      <IonToast
        isOpen={showToast}
        position="top"
        onDidDismiss={() => _showToast(false)}
        message="👀Looking for near by 🛢tinkans..."
        duration={5000}
      />

      <div style={{ width: "calc(100%)" }}>
        <div
          style={{
            marginTop: 0,
            fontSize: 20,
            fontWeight: 900,
            display: "inline-block",
            width: "100%",
            verticalAlign: "bottom",
          }}
        >
          📡 Public Collections
        </div>

        <div className="lines"></div>
        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "100%",
            display: "inline-block",
          }}
        >
          <IonButton
            size="small"
            color="light"
            expand="block"
            onClick={() => {
              props.showGroupModal();
            }}
          >
            ➕ <div style={{ fontSize: 12 }}> &nbsp;&nbsp;NEW</div>
          </IonButton>
        </div>
        {/* <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "50%",
            display: "inline-block",
          }}
        >
          <IonButton
            size="small"
            color="light"
            expand="block"
            onClick={() => {
              //_showGroupModal(true);
              history.push("/map");
            }}
          >
            🌎<div style={{ fontSize: 12 }}> &nbsp;&nbsp;MAP</div>
          </IonButton>
        </div> */}
        <div style={{ textAlign: "center", minHeight: 30 }}>
          {items && !items[0] && (
            <div style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div>No places near you! 🧐</div>
              <IonButton
                onClick={() => {
                  _items(null);
                  getNearKans();
                }}
              >
                Refresh
              </IonButton>
            </div>
          )}
          {items ? (
            List()
          ) : (
            <StoredImage
              style={{ marginBottom: 0, width: 200 }}
              src="/assets/radar4.gif"
            />
          )}
        </div>
        <div style={{ height: 20 }}></div>
        {locationError && <div style={{ color: "red" }}>{locationError}</div>}
      </div>
    </>
  );
}
