import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db from "../../services/db";
import { imageUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";
import { atcb_action, atcb_init } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

interface Props {
  item: any;
  emoji: string;
  showDetails: boolean;
}

export default function BlockEvent(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const history = useHistory();
  let eventDetails: any = JSON.parse(props.item.body);

  // console.log("item", props.item);
  return (
    <>
      <div
        style={{ paddingTop: 0 }}
        onClick={() => {
          history.push(
            "/group/" + props.item.groupId + "/block/" + props.item.$id
          );
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            width: 34,
            paddingTop: 10,
          }}
        >
          {props && props.item && props.item.emoji && (
            <Emoji unified={props.item.emoji} size={28} />
          )}
        </div>
        <div
          style={{
            display: "inline-block",
            padding: 8,
            borderRadius: 8,
            width: "calc(100% - 40px)",
          }}
        >
          <div style={{ fontSize: 18, fontWeight: 900 }}>
            {props.item.name}
            <br />
            {/* <StoredImage id={itemProps.item.imageId} style={{width:"100%",height:100}} /> */}
          </div>
          <div
            className="block-subtext"
            style={{ textAlign: "left", width: "100%" }}
          >
            {moment(props.item.$createdAt).fromNow()}
          </div>
        </div>
      </div>
    </>
  );
}
