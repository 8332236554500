import { IonAvatar, IonCol, IonGrid, IonRow } from "@ionic/react";
import { Query } from "appwrite";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import Store, { imageUrl } from "../services/storage";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";

interface Props {
  src: string;
  style?: any;
  preview?: boolean;
  alt?: string;
}

export default function StoredImage(props: Props) {
  const [image, _image] = useState<any>(props.src);

  // little hack to tell we fix the storage
  return <img alt={props.alt || "image"} src={image} style={props.style} />;
}
