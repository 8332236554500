import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonRow,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import UserAccount from "../services/account";
import User from "../services/users";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { accountState, userState } from "../services/state";
import Db, { BUCKET_ID, COLLECTION_ID, DATABASE_ID } from "../services/db";
import Store from "../services/storage";
import StoredImage from "./StoredImage";

interface Props {
  name: string;
}

export default function MyProfile(props: Props) {
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [name, _name] = useState<any>(user?.username);
  const [touched, _touched] = useState<boolean>(false);
  const [paypal_me, _paypal_me] = useState<any>(user?.paypal_me);
  const [imageId, _imageId] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    if (!user || !user.$id) {
      if (localStorage.getItem("user")) {
        let localUser = JSON.parse(localStorage.getItem("user") || "{}");
        Db.getDocument(DATABASE_ID, COLLECTION_ID.users, localUser.$id).then(
          (userdata) => {
            _user(userdata);
            _name(userdata.username);
            _paypal_me(userdata.paypal_me);
          }
        );
      }
    }
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: 24,
        padding: 24,
      }}
    >
      {user ? (
        <>
          <label className="custom-file-upload-user">
            <input
              accept="image/*"
              capture
              type="file"
              onChange={(e) => {
                let file = e.target.files && e.target.files[0];
                file &&
                  Store.createFile(BUCKET_ID.images, "unique()", file).then(
                    (fileData) => {
                      _imageId(fileData.$id);
                      let u: any = JSON.parse(JSON.stringify(user));
                      u.imageId = fileData.$id;
                      _user(u);

                      Db.updateDocument(
                        DATABASE_ID,
                        COLLECTION_ID.users,
                        user.$id,
                        {
                          imageId: fileData.$id,
                        }
                      );
                    }
                  );
              }}
            />
            {user.imageId ? (
              <StoredImage
                src={user.imageId}
                style={{
                  width: "50%",
                  height: "auto",
                  borderRadius: "5%",
                  border: "4px solid black",
                }}
              />
            ) : (
              <StoredImage
                src="https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                style={{ width: "50%", height: "auto" }}
              />
            )}
          </label>
          <h1>{account.email}</h1>
          <div style={{ fontWeight: "bold" }}>Public Username</div>
          <IonInput
            value={name}
            onIonChange={(e) => {
              _name(e.target.value);
              _touched(true);
            }}
            className="block-input"
            placeholder="Enter Name"
          ></IonInput>
          <div style={{ paddingTop: 20 }}></div>

          <IonButton
            onClick={() => {
              _touched(false);
              Db.updateDocument(DATABASE_ID, COLLECTION_ID.users, user.$id, {
                username: name,
                paypal_me: paypal_me.replace("@", ""),
              });
            }}
            disabled={!touched}
            expand="block"
            shape="round"
            color="dark"
          >
            Save
          </IonButton>
          <div style={{ paddingTop: 50 }}>
            <IonButton
              onClick={() => {
                localStorage.clear();
                UserAccount.deleteSessions();
                window.location.replace("/");
              }}
              expand="block"
              shape="round"
              size="small"
              color="light"
            >
              Log Out
            </IonButton>
          </div>
        </>
      ) : (
        <h1>Loading</h1>
      )}
    </div>
  );
}
