import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import {
  cameraOutline,
  checkmark,
  checkmarkCircleOutline,
  pencilOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { convertToRaw, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from "moment";
import { Emoji } from "emoji-picker-react";
import GroupModal from "./GroupModal";
import Db, { BUCKET_ID, COLLECTION_ID, DATABASE_ID } from "../services/db";
import Store from "../services/storage";

interface Props {
  group: any;
  user: any;
  updateGroup: () => void;
}

export default function TechnicalStuff(props: Props) {
  const history = useHistory();
  const [showEditModal, _showEditModal] = useState(false);
  const [imageFile, _imageFile] = useState<any>();
  const [isPrivate, _isPrivate] = useState(props.group.isPrivate);
  const [isPromoted, _isPromoted] = useState(props.group.isPromoted);
  const [allowCaptions, _allowCaptions] = useState(props.group.allowCaptions);

  return (
    <>
      <h1>Technical Stuff</h1>
      <div className="block-subtext">Your are the owner of this 🛢TINKAN </div>
      <div style={{ position: "relative" }}>
        <label className="custom-group-file-upload">
          <input
            accept="image/*"
            type="file"
            onChange={(e) => {
              let file = e.target.files && e.target.files[0];
              _imageFile(file);
              file &&
                Store.createFile(BUCKET_ID.images, "unique()", file).then(
                  (fileData) => {
                    Db.updateDocument(
                      DATABASE_ID,
                      COLLECTION_ID.groups,
                      props.group.$id,
                      {
                        imageId: fileData.$id,
                      }
                    ).then(() => {});
                  }
                );
            }}
          />
          <IonIcon style={{ fontSize: 54 }} icon={cameraOutline} />
          <div
            style={{
              position: "relative",
              height: "100%",
              width: "70%",
              display: "inline-block",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                marginTop: 0,
                display: "inline-block",
                position: "absolute",
                top: 14,
                left: 4,
              }}
            >
              {imageFile?.name}
            </div>
          </div>
        </label>
      </div>
      <h4>Crowd Settings</h4>

      {/* <IonItem lines="none">
        <IonLabel>OPEN - Anyone can drop facts</IonLabel>
        <IonToggle slot="start"></IonToggle>
      </IonItem> */}

      <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
        <IonItem lines="none">
          <IonLabel>
            &nbsp;
            {isPrivate
              ? " Closed - Only you can add a story"
              : " Open - Anyone can add a story"}
          </IonLabel>
          <IonToggle
            color={"secondary"}
            checked={isPrivate}
            onIonChange={(e) => {
              console.log(e.target.checked);
              _isPrivate(e.target.checked);
              Db.updateDocument(
                DATABASE_ID,
                COLLECTION_ID.groups,
                props.group.$id,
                {
                  isPrivate: e.target.checked,
                }
              ).then(() => {
                props.updateGroup();
              });
            }}
            slot="start"
          ></IonToggle>
        </IonItem>
      </div>

      <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
        <IonItem lines="none">
          <IonLabel>
            &nbsp;
            {allowCaptions ? " Captions - On" : " Captions - Off"}
          </IonLabel>
          <IonToggle
            color={"secondary"}
            checked={allowCaptions}
            onIonChange={(e) => {
              _allowCaptions(e.target.checked);
              Db.updateDocument(
                DATABASE_ID,
                COLLECTION_ID.groups,
                props.group.$id,
                {
                  allowCaptions: e.target.checked,
                }
              ).then(() => {
                props.updateGroup();
              });
            }}
            slot="start"
          ></IonToggle>
        </IonItem>
      </div>

      <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
        <IonItem lines="none">
          <IonLabel>
            &nbsp;
            {isPromoted
              ? " Public - For the world to see"
              : " Private - Use a secret link🚪🤫"}
          </IonLabel>
          <IonToggle
            color={"secondary"}
            checked={isPromoted}
            onIonChange={(e) => {
              _isPromoted(e.target.checked);
              Db.updateDocument(
                DATABASE_ID,
                COLLECTION_ID.groups,
                props.group.$id,
                {
                  isPromoted: e.target.checked,
                }
              ).then(() => {
                props.updateGroup();
              });
            }}
            slot="start"
          ></IonToggle>
        </IonItem>
      </div>

      <div style={{ paddingTop: 12 }}>
        <IonButton
          onClick={() => {
            _showEditModal(true);
          }}
          expand="block"
          shape="round"
          color="dark"
        >
          Change things
        </IonButton>
      </div>
      <GroupModal
        group={props.group}
        show={showEditModal}
        dismiss={() => {
          _showEditModal(false);
        }}
      />

      <div style={{ paddingTop: 48, textAlign: "center" }}>
        <Emoji unified="1f92f" />
        <IonButton
          onClick={() => {
            Db.deleteDocument(
              DATABASE_ID,
              COLLECTION_ID.groups,
              props.group.$id
            ).then(() => {
              history.replace("/home");
            });
          }}
          expand="block"
          shape="round"
          size="small"
          color="danger"
        >
          Delete?
        </IonButton>
      </div>
    </>
  );
}
