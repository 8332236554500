import { IonAlert, IonButton, useIonAlert } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";

interface Props {
  message: any;
  sender: any;
  user: any;
  deletedMessage: any;
}

export default function ChatDeleteMessage(props: Props) {
  const [presentAlert] = useIonAlert();

  const history = useHistory();
  const [really, _really] = useState(false);
  if (props.sender.$id == props.user.$id) {
    return (
      <>
        {!really ? (
          <div
            style={{
              fontSize: "small",
              fontWeight: "bold",
              marginTop: 10,
              marginBottom: -25,
            }}
            onClick={() => {
              _really(true);
            }}
          >
            🗑
          </div>
        ) : (
          <>
            <div
              style={{
                fontSize: "small",
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: -25,
                display: "inline-block",
              }}
              onClick={() => {}}
            >
              <IonButton
                onClick={() => {
                  Db.deleteDocument(
                    DATABASE_ID,
                    COLLECTION_ID.messages,
                    props.message.$id
                  );
                  props.deletedMessage(props.message.$id);
                }}
                size="small"
                color="danger"
              >
                Are you sure?
              </IonButton>
            </div>
            <div
              style={{
                fontSize: "small",
                fontWeight: "bold",
                marginTop: 10,
                marginBottom: -5,
                display: "inline-block",
              }}
              onClick={() => {}}
            >
              <IonButton
                onClick={() => {
                  _really(false);
                }}
                size="small"
                color="light"
              >
                cancel
              </IonButton>
            </div>
          </>
        )}
      </>
    );
  } else {
    return null;
  }
}
