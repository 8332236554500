import React, { useEffect, useRef, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { audioUrl, imageUrl } from "../../services/storage";
import { IonAvatar, IonButton, IonIcon } from "@ionic/react";
import {
  Captions,
  Download,
  Fullscreen,
  Slideshow,
  Thumbnails,
  Video,
  Zoom,
  Inline,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import AudioPlayer from "react-modern-audio-player";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { membersState } from "../../services/state";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import { Query } from "appwrite";
import StoredImage from "../StoredImage";
import {
  chatboxEllipsesOutline,
  close,
  closeOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";
import Chat from "../Chat";
import LogBook from "../LogBook";

interface Props {
  playlist: any;
  index?: number;
  open?: boolean;
  user: any;
  groupId: string;
  setOpen: (open: boolean) => void;
  isPlaying: boolean;
}

export default function PlayListLightBox(props: Props) {
  const [open, setOpen] = useState(false);
  const [playlist, _playlist] = useState<any>([]);
  const [audioFile, _audioFile] = useState<string>();
  const [showAudio, _showAudio] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const [members, _members] = useRecoilState<any>(membersState);
  const [image, _image] = useState<any>(null);
  const [delay, _delay] = useState(3000);
  const [hideStuff, _hideStuff] = useState(false);
  const [showChat, _showChat] = useState(false);

  const captionsRef = useRef<any>();
  const audionRef = useRef<any>();
  const fullscreenRef = useRef<any>();

  const history = useHistory();

  useEffect(() => {
    let plist = [];
    // console.log("open", open);
    for (let p of props.playlist) {
      // console.log(p);
      if (p.imageId) {
        plist.push({
          src: imageUrl(p.imageId, false),
          title: p.name,
          description: p.body,
        });
      }
    }
    _playlist(plist);
  }, [open]);

  useEffect(() => {
    // console.log(props.groupId);
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
      Query.equal("groupId", props.groupId),
    ]).then((groupmems: any) => {
      Db.listDocuments(DATABASE_ID, COLLECTION_ID.users, [
        Query.equal(
          "$id",
          groupmems.documents.map((x: any) => x.userId)
        ),
      ]).then((mems: any) => {
        // console.log("list members", mems);
        _members(mems.documents);
      });
    });
  }, []);

  return (
    <>
      <Lightbox
        index={props.index || 0}
        plugins={[
          Captions,
          Download,
          Fullscreen,
          Slideshow,
          Thumbnails,
          Video,
          Zoom,
        ]}
        fullscreen={{ ref: fullscreenRef }}
        slideshow={{ delay: delay || 4000 }}
        render={{
          controls: () => (
            <div>
              {showChat && (
                <LogBook
                  image={image}
                  hideChat={() => {
                    _showChat(false);
                  }}
                />
              )}
              {showAudio && audioFile != null && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 60,
                    left: 20,
                    borderRadius: 40,
                    display: hideStuff ? "none" : "unset",
                    paddingBottom: 0,
                    backgroundColor: "white",
                    width: "calc(100% - 40px)",
                    zIndex: 10,
                    border: "4px solid black",
                    borderBottom: "0px solid #ccc !important",
                    boxShadow: "0px 0px 0px 0px #ccc !important",
                  }}
                >
                  <AudioPlayer
                    audioInitialState={{
                      curPlayId: 1,
                      isPlaying: props.isPlaying,
                    }}
                    activeUI={{
                      volume: true,
                      trackTime: true,
                      progress: "bar",
                      playButton: true,
                    }}
                    playList={[
                      {
                        id: 1,
                        src: audioFile,
                      },
                    ]}
                  />
                </div>
              )}
              {createdBy && (
                <>
                  <IonAvatar
                    onClick={() => {
                      createdBy.$id == props.user.$id && history.push("/me");
                    }}
                    style={{
                      width: 50,
                      height: 50,
                      border: "3px black solid",
                      position: "absolute",
                      top: 60,
                      left: 20,
                      zIndex: 100,
                    }}
                  >
                    <StoredImage
                      alt="person"
                      src={
                        createdBy && createdBy.imageId
                          ? imageUrl(createdBy.imageId)
                          : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                      }
                    />
                  </IonAvatar>
                  {createdBy.$id == props.user.$id && image && (
                    <IonButton
                      style={{
                        position: "absolute",
                        top: 55,
                        right: 10,
                        zIndex: 100,
                      }}
                      onClick={() => {
                        history.replace(`/block/edit/` + image.$id);
                      }}
                    >
                      <b>EDIT</b>
                    </IonButton>
                  )}
                </>
              )}
              {/* <div>
                <IonButton
                  color="light"
                  style={{
                    position: "absolute",
                    top: 55,
                    right: 20,
                    zIndex: 100,
                  }}
                  onClick={() => {
                    _showChat(!showChat);
                  }}
                >
                  📒 Log Book
                </IonButton>
              </div> */}
            </div>
          ),
        }}
        captions={{ ref: captionsRef }}
        on={{
          click: () => {
            (captionsRef.current?.visible
              ? captionsRef.current?.hide
              : captionsRef.current?.show)?.();
            _hideStuff(!hideStuff);
          },

          view: (index: any) => {
            _hideStuff(false);
            captionsRef.current?.show?.();
            let i = 0;
            let pl = props.playlist[index.index];
            for (let m of members) {
              // console.log(members, pl.createdBy);
              if (m.accountId == pl.createdBy) {
                _image(pl);
                _createdBy(m);
                break;
              }
            }
            _showAudio(false);
            if (pl && pl.audioId) {
              // console.log("audio", audioUrl(pl.audioId));
              let f = audioUrl(pl.audioId);
              // console.log("audio", f);
              _audioFile(f);
              setTimeout(() => {
                _showAudio(true);
              }, 500);

              // _audioFile(audioUrl(pl.audioId));
            } else {
              _showAudio(false);
            }
          },
        }}
        open={props.open || false}
        close={() => props.setOpen(false)}
        slides={playlist}
      />
    </>
  );
}
