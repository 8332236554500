import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonViewWillLeave,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import {
  arrowBack,
  arrowBackCircle,
  arrowForward,
  arrowForwardCircle,
  close,
  closeCircleOutline,
  copyOutline,
  downloadOutline,
  eye,
  eyeOff,
  eyeOffOutline,
  eyeOutline,
  link,
  linkOutline,
  shareOutline,
} from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import { audioUrl, imageUrl, videoUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";
import StoredImage from "../StoredImage";
import Microphone from "./Microphone";
// import AudioPlayer from "react-h5-audio-player";
import AudioPlayer from "react-modern-audio-player";

import { Howl, Howler } from "howler";
import "react-h5-audio-player/lib/styles.css";
import { useRecoilState } from "recoil";

import {
  groupState,
  playlistState,
  playlistStateIndex,
  recorderState,
  showMeHowState,
  userState,
} from "../../services/state";
import { linkSync } from "fs";
import Chat from "../Chat";

interface Props {
  item: any;
  emoji: string;
  canEdit: boolean;
  update: (data: any) => void;
  playlist?: [];
  isPublic?: boolean;
}

export default function PageImage(props: Props) {
  const [user, _user] = useRecoilState<any>(userState);

  const [showMeHow, _showMeHow] = useRecoilState(showMeHowState);
  const [group, _group] = useRecoilState<any>(groupState);
  const [isOpen, _isOpen] = useState(false);
  const [recorder, _recorder] = useRecoilState<any>(recorderState);
  const [createdBy, _createdBy] = useState<any>();
  const [imageCover, _imageCover] = useState(true);
  const [audioSaved, _audioSaved] = useState(false);
  const [audioID, _audioID] = useState<any>();
  const [playlist, _playlist] = useRecoilState(playlistState);
  const [playlistIndex, _playlistIndex] = useRecoilState(playlistStateIndex);
  const history = useHistory();
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    console.log("item", props.item);
    console.log("group", group);

    Db.listDocuments(DATABASE_ID, COLLECTION_ID.users, [
      Query.equal("accountId", props.item.createdBy),
    ]).then((data) => {
      // console.log("createdBy", data.documents[0]);
      _createdBy(data.documents && data.documents[0]);
    });
  }, []);

  useIonViewWillLeave(() => {
    if (recorder) {
      recorder.stop();
    }
  });

  function isOwner() {
    if (user && group && user.$id == group.createdBy) {
      return true;
    } else {
      return false;
    }
  }

  // console.log("item", props.item);
  return (
    <>
      <IonPage style={{}}>
        {" "}
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="light"
            onClick={() => {
              _playlistIndex(0);
              history.replace("/group/" + props.item.groupId);
            }}
          >
            <IonIcon style={{ fontSize: 54 }} icon={close} />
          </IonFabButton>
        </IonFab>
        <IonContent id="content">
          {props.emoji == "1f3a5" ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                overflow: "hidden",
                backgroundColor: "black",
              }}
            >
              <video
                autoPlay
                loop
                controls={true}
                playsInline
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "contain",
                }}
              >
                <source src={videoUrl(props.item.imageId)} type="video/mp4" />
              </video>
            </div>
          ) : (
            <img
              onClick={() => {
                _imageCover(!imageCover);
              }}
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                width: "100%",
                height: "100vh",
                objectFit: imageCover ? "cover" : "contain",
                minWidth: "100%",
                borderRadius: "0px 0px 10px 10px",
                backgroundColor: "#eee",
              }}
              src={imageUrl(props.item.imageId, false)}
              onError={(e: any) => {
                e.target.src = imageUrl(props.item.imageId, false).replace(
                  "",
                  "images"
                );
              }}
            />
          )}
          {/* {playlist && playlist[1] && (
            <>
              <div
                style={{
                  position: "fixed",
                  bottom: 40,
                  right: 10,
                  fontSize: 40,
                  background: "white",
                  borderRadius: 10,
                  height: 48,
                  border: "4px solid black",
                }}
                onClick={() => {
                  let newIndex = playlistIndex + 1;
                  if (playlist[newIndex]) {
                    _playlistIndex(newIndex);
                  } else {
                    _playlistIndex(0);
                    newIndex = 0;
                  }

                  history.replace(
                    "/group/" +
                      props.item.groupId +
                      "/" +
                      (newIndex % 2 == 0 ? "block" : "block_") +
                      "/" +
                      playlist[newIndex]
                  );
                }}
              >
                <IonIcon icon={arrowForward} />
              </div>
              {playlistIndex >= 1 && (
                <div
                  style={{
                    position: "fixed",
                    bottom: 40,
                    left: 10,
                    fontSize: 40,
                    background: "white",
                    borderRadius: 10,
                    height: 48,
                    border: "4px solid black",
                  }}
                  onClick={() => {
                    let newIndex = playlistIndex - 1;
                    if (playlist[newIndex]) {
                      _playlistIndex(newIndex);
                    } else {
                      newIndex = 0;
                      _playlistIndex(0);
                    }

                    history.push(
                      "/group/" +
                        props.item.groupId +
                        "/block/" +
                        playlist[newIndex]
                    );
                  }}
                >
                  <IonIcon icon={arrowBack} />
                </div>
              )}
            </>
          )} */}
          <div style={{}}>
            <div
              style={{
                position: "fixed",
                top: 13,
                left: 10,
                borderRadius: 50,
                paddingRight: 20,
                height: 48,
                color: "white",
              }}
            >
              <div
                style={{
                  paddingRight: 5,
                  display: "inline-block",
                }}
              >
                {createdBy && (
                  <IonAvatar
                    onClick={() => {
                      createdBy.$id == user.$id && history.push("/me");
                    }}
                    style={{ border: "3px black solid" }}
                  >
                    <img
                      alt="person"
                      src={
                        createdBy && createdBy.imageId
                          ? imageUrl(createdBy.imageId)
                          : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                      }
                    />
                  </IonAvatar>
                )}
              </div>
              {/* <div style={{ display: "inline-block", verticalAlign: "top" }}>
                <div
                  className=""
                  style={{ textAlign: "left", fontWeight: 900, width: "100%" }}
                >
                  {createdBy?.username}
                </div>
                <div
                  className="block-subtext"
                  style={{ textAlign: "left", width: "100%", color: "white" }}
                >
                  {moment(props.item.$createdAt).fromNow()}
                </div>
              </div> */}
            </div>

            {/* <div
              onClick={() => {
                _imageCover(!imageCover);
              }}
              style={{
                width: "100%",
                textAlign: "left",
                height: "70vh",
                backgroundImage: imageUrl(props.item.imageId, false),
              }}
            ></div> */}
            <div
              style={{
                position: "fixed",
                top: 83,
                right: 10,
                width: "calc(100% - 20px)",
                maxHeight: "calc(100vh - 200px)",
                overflow: "auto",
                borderRadius: 10,
              }}
            >
              {showText && props.item.name && (
                <div
                  style={{
                    borderRadius: 10,
                    paddingRight: 20,
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontSize: 26,
                    color: "white",
                    backgroundColor: "black",
                    zIndex: 10000000000,
                    padding: 10,
                    fontWeight: 900,
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <span>{props.item.name}</span>
                </div>
              )}
              {showText && props.item.body && (
                <div
                  style={{
                    borderRadius: 10,
                    paddingRight: 22,
                    marginTop: 10,
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontSize: 20,
                    color: "white",
                    zIndex: 10000000000,
                    padding: 10,
                    fontWeight: 900,
                    width: "fit-content",
                    textAlign: "center",
                  }}
                >
                  <span style={{ backgroundColor: "black" }}>
                    {props.item.body}
                  </span>
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: 30,
                paddingTop: 6,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 11,
                textAlign: "center",
                position: "fixed",
                bottom: 40,
                right: 16,
                backgroundColor: "white",
                borderRadius: 10,
                border: "4px solid black",
              }}
            >
              <div
                style={{ display: "inline-block", paddingRight: 36 }}
                onClick={() => {
                  setShowText(!showText);
                }}
              >
                {!showText ? (
                  <IonIcon icon={eyeOutline} />
                ) : (
                  <IonIcon icon={eyeOffOutline} />
                )}
              </div>
              <div style={{ display: "inline-block" }}>
                <a
                  target="_blank"
                  href={imageUrl(props.item.imageId, false).replace(
                    "view",
                    "download"
                  )}
                >
                  <IonIcon icon={downloadOutline} />
                </a>
              </div>
            </div>
            <div style={{}}>
              {props.item.name && <h2>{props.item.name}</h2>}
            </div>
            <div
              style={{
                width: "100%",
                borderRadius: 10,
                overflow: "hidden",
              }}
            >
              {!props.item.audioId && audioSaved != true ? (
                <>
                  {props.canEdit && (
                    <div
                      style={{
                        textAlign: "center",
                        position: "fixed",
                        top: 74,
                        right: 10,
                        fontWeight: 900,
                        borderRadius: 10,
                        backgroundColor: "white",
                        padding: 10,
                        cursor: "pointer",
                        zIndex: 10000000000,
                        fontSize: 15,
                        border: "4px solid black",
                        boxShadow: "0px 0px 0px 0px #ccc !important",
                      }}
                      onClick={() => {
                        _showMeHow(true);
                        history.push("/block/edit/" + props.item.$id);
                      }}
                    >
                      Edit
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    position: "fixed",
                    bottom: 100,
                    left: 10,
                    borderRadius: 40,
                    overflow: "hidden",
                    paddingBottom: 0,
                    backgroundColor: "white",
                    width: "calc(100% - 20px)",
                    zIndex: 10000000000,

                    border: "4px solid black",
                    borderBottom: "0px solid #ccc !important",
                    boxShadow: "0px 0px 0px 0px #ccc !important",
                  }}
                >
                  {/* <AudioPlayer
                    className="rhap_loop--off	"
                    style={{
                      width: "100%",
                      borderBottom: "0px solid #ccc !important",
                      boxShadow: "0px 0px 0px 0px #ccc !important",
                    }}
                    autoPlay={false}
                    showSkipControls={false}
                    src={audioUrl(props.item.audioId)}
                    onPlay={(e) => console.log("onPlay")}
                    onEnded={(e) => {
                      // if (!user || !user.$id) {
                      // } else {
                      //   _showMeHow(true);
                      //   history.goBack();
                      // }
                    }}
                    // other props here
                  /> */}
                  <AudioPlayer
                    activeUI={{
                      volume: true,
                      trackTime: true,
                      progress: "bar",
                      playButton: true,
                    }}
                    playList={[{ id: 1, src: audioUrl(props.item.audioId) }]}
                  />
                </div>
              )}
            </div>
            {props.item.body && (
              <div style={{ paddingLeft: 16, paddingBottom: 10 }}>
                <span style={{ color: "#9a9a9a", fontSize: "small" }}>
                  ✱ More Details
                </span>
                <br />
                {props.item.body}
              </div>
            )}
          </div>

          {/* <div style={{ marginTop: 20 }}>
            <Chat relatedId={props.item.$id} />
          </div> */}
          {/* {group && !group.isPrivate && (
            <div
              style={{ textAlign: "center", paddingTop: 30, marginBottom: 60 }}
            >
              <IonButton
                size="small"
                color="secondary"
                shape="round"
                onClick={() => {
                  if (!user || !user.$id) {
                    let url = "/group/" + props.item.groupId;
                    localStorage.setItem("after_login", url);
                    history.push("/login");
                  } else {
                    _showMeHow(true);
                    history.goBack();
                  }
                }}
              >
                Add Your Own Picture To The Collection
              </IonButton>
            </div>
          )} */}
        </IonContent>
      </IonPage>
    </>
  );
}
