import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import {
  calendar,
  calendarOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  server,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import UserAccount from "../services/account";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, loginUserState, userState } from "../services/state";
import User from "../services/users";

function Code() {
  const [selectedSegment, _selectedSegment] = useState<any>("alldates");
  const [code, _code] = useState<any>();
  const [password, _password] = useState<any>();
  const [errorMessage, _errorMessage] = useState<any>();
  const [skippedHomeScreen, _skippedHomeScreen] = useState(false);
  const [loginUser, _loginUser] = useRecoilState<any>(loginUserState);

  const history = useHistory();

  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);

  function isRunningStandalone() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  useEffect(() => {
    console.log("after login", localStorage.getItem("after_login"));
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div style={{ textAlign: "center" }}>
          <div style={{ fontWeight: 900, fontSize: 80, marginTop: 50 }}>
            <IonIcon icon={serverOutline} />
          </div>
          <div style={{ fontWeight: 900, fontSize: 40 }}>TINKAN</div>
          <>
            <h1>Login</h1>
            <div style={{ paddingTop: 30 }}>
              <div>Enter Code From SMS</div>

              <IonInput
                type="number"
                value={code}
                onIonChange={(e) => {
                  _code(e.target.value);
                }}
                className="block-input"
                placeholder="000000"
              ></IonInput>
            </div>
            <div style={{ color: "red" }}>{errorMessage}</div>
            <br />
            <IonButton
              disabled={!code}
              expand="block"
              onClick={() => {
                UserAccount.updatePhoneSession(loginUser, code).then(
                  (data) => {
                    console.log("data", data);
                    UserAccount.get().then((accountData) => {
                      console.log("accountData", accountData);
                      Db.listDocuments(DATABASE_ID, COLLECTION_ID.users, [
                        Query.equal("accountId", accountData.$id),
                      ]).then((usersData: any) => {
                        _account(accountData);

                        if (
                          usersData &&
                          usersData.documents &&
                          usersData.documents[0]
                        ) {
                          if (accountData.name != usersData.documents[0].$id) {
                            UserAccount.updateName(usersData.documents[0].$id);
                            let newAccount = accountData;
                            newAccount.name = usersData.documents[0].$id;
                            _account(newAccount);
                          }

                          console.log("user exists", usersData.documents[0]);
                          _user(usersData.documents[0]);

                          localStorage.setItem(
                            "user",
                            JSON.stringify(usersData.documents[0])
                          );
                          setTimeout(() => {
                            let url = "/home";
                            let after_login =
                              localStorage.getItem("after_login");
                            if (after_login != null) {
                              url = after_login;
                            }
                            window.location.href = url;
                          }, 500);
                        } else {
                          Db.createDocument(
                            DATABASE_ID,
                            COLLECTION_ID.users,
                            "unique()",
                            {
                              accountId: accountData.$id,
                            }
                          ).then((userData) => {
                            if (accountData.name != userData.$id) {
                              UserAccount.updateName(userData.$id);
                              let newAccount = structuredClone(accountData);
                              newAccount.name = userData.$id;
                              _account(newAccount);
                            }
                            _user(userData);
                            localStorage.setItem(
                              "user",
                              JSON.stringify(userData)
                            );
                            setTimeout(() => {
                              let url = "/home";
                              let after_login =
                                localStorage.getItem("after_login");
                              if (after_login != null) {
                                url = after_login;
                              }
                              //window.location.href = url;
                              window.location.href = url;
                            }, 500);
                          });
                        }
                      });
                    });
                  },
                  (error) => {
                    console.log("error", error);
                    _errorMessage(error.message);
                  }
                );
              }}
            >
              Login
            </IonButton>
            <br />
            <IonButton
              size="small"
              color="light"
              onClick={() => {
                history.replace("/login");
              }}
            >
              Try Again
            </IonButton>
          </>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Code;
