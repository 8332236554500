import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close } from "ionicons/icons";
import moment from "moment";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import Db from "../../services/db";
import { imageUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";
import { atcb_action, atcb_init } from "add-to-calendar-button";
import "add-to-calendar-button/assets/css/atcb.css";

interface Props {
  item: any;
  emoji: string;
  isPublic?: boolean;
}

export default function PageEvent(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [createdBy, _createdBy] = useState<any>();
  const [eventDetails, _eventDetails] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    _eventDetails(JSON.parse(props.item.body));
    atcb_init();
    Db.listDocuments("tinkan", "users", [
      Query.equal("accountId", props.item.createdBy),
    ]).then((data) => {
      // console.log("createdBy", data.documents[0]);
      _createdBy(data.documents && data.documents[0]);
    });
  }, []);

  // console.log("item", props.item);
  return (
    <>
      <IonPage>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol
                size="8"
                style={{ paddingTop: 14, fontSize: 34, fontWeight: 900 }}
              >
                <div style={{ display: "inline-block" }}>
                  <Emoji size={38} unified={props.emoji} />
                </div>
                <div style={{ display: "inline-block", verticalAlign: "top" }}>
                  &nbsp; {emojisSelectUnified(props.emoji)?.label}
                </div>
              </IonCol>
              <IonCol
                onClick={() => {
                  history.replace("/group/" + props.item.groupId);
                }}
                style={{ textAlign: "right" }}
              >
                <IonIcon style={{ fontSize: 54 }} icon={close} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent className="ion-padding">
          <div style={{ fontWeight: 900, fontSize: 34 }}>{props.item.name}</div>
          <div style={{ width: 70, display: "inline-block" }}></div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <div className="" style={{ textAlign: "left", width: "100%" }}>
              {createdBy?.username}
            </div>
            <div
              className="block-subtext"
              style={{ textAlign: "left", width: "100%" }}
            >
              {moment(props.item.$createdAt).fromNow()}
            </div>
          </div>
          <div>
            <div className="time-box">
              START <br />
              {eventDetails &&
                eventDetails.startDate &&
                moment(
                  eventDetails.startDate + " " + eventDetails.startTime
                ).format("lll")}
            </div>
            <div className="time-box">
              END <br />
              {eventDetails &&
                eventDetails.endDate &&
                moment(
                  eventDetails.endDate + " " + eventDetails.endTime
                ).format("lll")}
            </div>
          </div>
          <div style={{ fontStyle: "italic", fontSize: "small" }}>
            Timezone: {eventDetails?.timeZone}
          </div>
          <div
            style={{ paddingTop: 10, fontWeight: "bold", fontSize: "small" }}
          >
            Location:
          </div>
          {eventDetails?.location}
          <div
            style={{ paddingTop: 10, fontWeight: "bold", fontSize: "small" }}
          >
            Description:
          </div>
          {eventDetails?.description}

          <div style={{ textAlign: "center", marginTop: 40 }}>
            <div className="atcb" style={{ display: "none" }}>
              {props.item.body}
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
