import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import EmojiPicker from "emoji-picker-react";
import {
  close,
  homeOutline,
  personOutline,
  serverOutline,
} from "ionicons/icons";
import { pick } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import MyProfile from "../components/MyProfile";
import SavedGroups from "../components/SavedGroups";
import CheckoutCredits from "../services/checkout";
import Checkout from "../services/checkout";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import {
  accountState,
  subscriptionState,
  userState,
  userTeamState,
} from "../services/state";
import User from "../services/users";

function Credits() {
  const [picked, _picked] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);
  const [credits, _credits] = useState<any>();
  const [subscription, _subscription] = useRecoilState<any>(subscriptionState);

  const history = useHistory();

  useEffect(() => {
    User.getAccount().then((res: any) => {
      _account(res);

      Db.listDocuments(DATABASE_ID, COLLECTION_ID.credits, [
        Query.equal("userId", res.$id),
      ]).then((res: any) => {
        _subscription(res.documents[0]);
        if (res.total != 0) {
          _credits(res.documents[0].credits);
        }
      });
    });
  }, []);

  return (
    <IonPage>
      <div
        style={{
          fontWeight: "bolder",
          fontSize: 50,
          width: "100%",
        }}
        onClick={() => history.replace("/home")}
      >
        <IonIcon style={{ fontSize: 54 }} icon={close} />
      </div>
      <IonContent>
        <div
          style={{
            marginTop: 20,
            fontSize: 32,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {subscription &&
          (subscription.subscription_basic || subscription.subscription_pro)
            ? "Membership"
            : COLLECTION_ID.credits}
        </div>
        <div></div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: 80,
            textAlign: "center",
            color: credits < 1 ? "red" : "#000",
          }}
        >
          {subscription && subscription.subscription_pro ? (
            <>
              🧐
              <br />
              Curator
            </>
          ) : subscription && subscription.subscription_pro ? (
            <>
              😎
              <br />
              Pro
            </>
          ) : credits > 1 ? (
            credits
          ) : (
            0
          )}
        </div>
        <div style={{ textAlign: "center", color: "grey", fontSize: "small" }}>
          {subscription &&
          (subscription.subscription_basic || subscription.subscription_pro)
            ? ""
            : "credits remaining"}
        </div>

        <div style={{ textAlign: "center", padding: 24 }}>
          {subscription &&
          (subscription.subscription_basic || subscription.subscription_pro) ? (
            <>
              We are still building and looking for feedback.
              <br />
              <a href="https://airtable.com/shrJytwtQi5gwMASS" target="_blank">
                {" "}
                Click here to leave some feedback{" "}
              </a>{" "}
              <br />
              and you could get win a free subscription for a year
              <br /> to 🎁gift to someone special.
            </>
          ) : (
            <>
              <b>What is a credit?</b>
              <br />
              Every time you upload an image to Tinkan, you use a credit.
              <br />
              <br />
              You can purchase credits by clicking one of the buttons below.
              <br />
              <br />
              <div style={{ textAlign: "center", position: "relative" }}>
                <b>OR</b>
                <br />
                <br />
                We are still test so.... <br />
                We are handing out credits like 🍬candy🍭.
                <br />
                <a
                  href="https://airtable.com/shrJytwtQi5gwMASS"
                  target="_blank"
                >
                  {" "}
                  Click here to leave some feedback{" "}
                </a>{" "}
                <br />
                and we will send you a 🪣 of credits.
              </div>
            </>
          )}
          <br />
          <br />
          <div style={{ textAlign: "center", position: "relative" }}>
            {/* <div
              style={{
                position: "relative",
                border: "2px solid black",
                padding: 12,
                borderRadius: 10,
                width: 300,

                verticalAlign: "top",
                height: 400,
                textAlign: "center",
                display: "inline-block",
                margin: 20,
              }}
            >
              <h1>
                <b>Basic</b>
              </h1>
              <div style={{ textAlign: "center", paddingTop: 20 }}>
                First 25 credits free
                <br />
                <br />
                Create <b>1 minute</b> audio recordings.
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  width: 275,
                }}
              >
                <div
                  style={{
                    fontSize: 60,
                    fontWeight: "bold",
                    marginTop: 26,
                    marginBottom: 26,
                  }}
                >
                  Free
                </div>
              </div>
            </div> */}
            <div
              style={{
                border: "2px solid black",
                padding: 12,
                borderRadius: 10,
                width: 300,
                textAlign: "center",
                margin: 20,
                position: "relative",
                height: 400,
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <h1>
                <b>Pro</b>
              </h1>
              <div style={{ textAlign: "center", paddingTop: 20 }}>
                Unlimited image uploads and groups.
                <br />
                <br />
                Create <b>2 minute</b> audio recordings.
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  width: 275,
                }}
              >
                <div
                  style={{
                    fontSize: 40,
                    fontWeight: "bold",
                    marginTop: 26,
                  }}
                >
                  $1.99
                </div>
                <div style={{ fontWeight: "bold", marginBottom: 26 }}>
                  per month
                </div>
                {subscription && subscription.subscription_pro ? (
                  <div style={{ marginBottom: 24 }}>
                    <b>Current Plan</b>
                  </div>
                ) : (
                  <div style={{ marginBottom: 24 }}>
                    {account &&
                      account.$id &&
                      CheckoutCredits(
                        account.$id,
                        "price_1MlzNRACGyibZhfHVBHKyxrN",
                        "subscription"
                      )}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                border: "2px solid black",
                padding: 12,
                borderRadius: 10,
                width: 300,
                textAlign: "center",
                margin: 20,
                height: 400,
                display: "inline-block",
                verticalAlign: "top",
                position: "relative",
              }}
            >
              <h1>
                <b>Curator</b>
              </h1>
              <div style={{ textAlign: "center", paddingTop: 20 }}>
                Unlimited monthly images uploads for yourself and your groups.
                Others will not use credits on your group.
                <br />
                <br />
                Create <b>10 minute</b> audio recordings.
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 20,
                  width: 275,
                }}
              >
                <div
                  style={{
                    fontSize: 40,
                    fontWeight: "bold",
                    marginTop: 26,
                  }}
                >
                  $12
                </div>
                <div style={{ fontWeight: "bold", marginBottom: 26 }}>
                  per year
                </div>
                {subscription && subscription.subscription_pro ? (
                  <div style={{ marginBottom: 24 }}>
                    <b> Current Plan</b>
                  </div>
                ) : (
                  <div style={{ marginBottom: 24 }}>
                    {account &&
                      account.$id &&
                      CheckoutCredits(
                        account.$id,
                        "price_1MlzMSACGyibZhfHSG40xeyn",
                        "subscription"
                      )}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                position: "relative",
                border: "2px solid black",
                padding: 12,
                borderRadius: 10,
                width: 640,
                verticalAlign: "top",

                textAlign: "center",
                display: "inline-block",
                margin: 10,
              }}
            >
              <div
                style={{
                  fontSize: 24,
                  fontWeight: "bold",
                  marginTop: 0,
                }}
              >
                Free
              </div>
              <div style={{ textAlign: "center", paddingTop: 10 }}>
                First 25 credits free. Create <b>1 minute</b> audio recordings.
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", paddingTop: 20, marginBottom: 100 }}>
          <IonButton onClick={() => history.goBack()} size="small" color="dark">
            cancel
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Credits;
