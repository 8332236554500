import { Client, Account, ID } from 'appwrite';
import { HOST_URI, PROJECT_ID } from './db';


const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

const UserAccount = new Account(client);

export async function UserCanEdit(doc) {
    return await UserAccount.get().then((userData) => {
        if (userData.$id && JSON.stringify(doc.$permissions).includes(userData.$id)) {
            return true
        } else {
            return false
        }
    })

}


export default UserAccount;