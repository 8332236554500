import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { useState } from "react";

import { useHistory } from "react-router";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";
import { Avatar, Message } from "@chatscope/chat-ui-kit-react";
import moment from "moment";
import { imageUrl } from "../services/storage";
import ChatDeleteMessage from "./ChatDeleteMessage";
import { close } from "ionicons/icons";
import Chat from "./Chat";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";

interface Props {
  image: any;
  hideChat: () => void;
}

export default function LogBook(props: Props) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "90%",
        maxWidth: 400,
        height: "calc(100vh - 210px)",
        backgroundColor: "white",
        zIndex: 1000,
        borderRadius: 20,
        margin: 20,
      }}
    >
      <div
        onClick={() => {
          props.hideChat();
        }}
        style={{
          position: "absolute",
          top: 10,
          left: 10,
          fontSize: 40,
          fontWeight: "bold",
          zIndex: 1,
        }}
      >
        <IonIcon icon={close} />
      </div>
      <h1 style={{ textAlign: "center" }}>📒LOG BOOK</h1>
      <div
        style={{
          marginBottom: 60,
          borderRadius: 20,
          overflow: "hidden",
          height: "calc(100vh - 210px)",
        }}
      >
        {props.image && props.image.$id && <Chat relatedId={props.image.$id} />}
      </div>
    </div>
  );
}
