import { Client, Account, ID, Databases } from 'appwrite';

export const DATABASE_ID = "64ee518077284dfcd44b";
export const PROJECT_ID = "64ee4b8cb1d5842e10fd";
export const HOST_URI = "https://cloud.appwrite.io/v1";
export const COLLECTION_ID = {
    captions: "64ee5da2c0f2ebd58ede",
    purchases: "64ee5e0eadbde4bb4c5f",
    credits: "64ee6578543b91ff4a75",
    messages: "64ee668c7d1bff7a49b1",
    tokens: "64ee66cc3fc9cdd7ba4e",
    wallets: "64ee671bf1c52b1d88f5",
    groupActivity: "64ee67747379a0fe159a",
    images: "64ee67bb79ffb84c4885",
    blocks: "blocks",
    groupMembers: "64ef5238cd893e56352e",
    groups: "64ef9913d67830af1ceb",
    users: "64ef53930b1c60d22d6e",
}
export const BUCKET_ID = {
    images: "images",
    audio: "audio",
    video: "video",
}


export const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

const Db = new Databases(client, DATABASE_ID);




export default Db;