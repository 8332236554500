import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import Picker, { Emoji } from "emoji-picker-react";
import { cameraOutline } from "ionicons/icons";
import * as _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { userState } from "../services/state";
import Store from "../services/storage";
import EmojiSelector from "./EmojiSelector";

interface Props {
  group?: any;
  show: boolean;
  dismiss: () => void;
}

export default function GroupModal(props: Props) {
  const [user, _user] = useRecoilState<any>(userState);

  const [name, _name] = useState<any>();
  const [emoji, _emoji] = useState<any>();
  const [imageId, _imageId] = useState<string>();
  const [description, _description] = useState<any>();
  const [isPrivate, _isPrivate] = useState(true);
  const [isPromoted, _isPromoted] = useState(false);
  const [allowCaptions, _allowCaptions] = useState(false);
  const [type, _type] = useState<any>();

  const [presentLoading, dismissLoading] = useIonLoading();
  // _description

  useEffect(() => {
    if (props.group) {
      _name(props.group.name);
      _emoji(props.group.emoji);
      _description(props.group.description);
    }
  }, []);

  const history = useHistory();

  return (
    <>
      <IonModal
        isOpen={props.show}
        onWillDismiss={() => {
          props.dismiss();
          _name(undefined);
          _emoji(undefined);
          _type(undefined);
          localStorage.removeItem("group_type");
        }}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons
              onClick={() => {
                props.dismiss();

                _name(undefined);
                _emoji(undefined);
                _description(undefined);
                setTimeout(() => {
                  _type(undefined);
                }, 500);
              }}
              slot="start"
            >
              <IonButton>Cancel</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <div style={{ fontSize: 34 }}> </div>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {!type ? (
            <>
              <h2>Choose your adventure</h2>
              <p>What is your 🛢tinkan about?</p>
              <div style={{ marginTop: 36 }}>
                <IonButton
                  size="large"
                  expand="block"
                  onClick={() => {
                    _type("place");
                  }}
                >
                  Place
                </IonButton>
              </div>
              <div
                style={{
                  marginTop: 16,
                  fontSize: 20,
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                OR 🧐
              </div>
              <div style={{ marginTop: 16 }}>
                <IonButton
                  size="large"
                  expand="block"
                  onClick={() => {
                    _type("topic");
                  }}
                >
                  Topic
                </IonButton>
              </div>
              <p style={{ paddingTop: 20, fontSize: 12 }}>
                *A place is tied to a specific location and will show up on the
                map. A topic is shared as a link and will have no specific
                location.
              </p>
            </>
          ) : (
            <>
              {!props.group && (
                <h1 style={{ textAlign: "center" }}>{type.toUpperCase()}</h1>
              )}
              <div
                style={{ backgroundColor: "#eee", borderRadius: 8, padding: 8 }}
              >
                <IonLabel style={{ fontWeight: 900 }} position="stacked">
                  Enter Name
                </IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e) => {
                    _name(e.detail.value);
                  }}
                  type="text"
                  placeholder="What shall we call it?"
                />
              </div>
              <div
                style={{
                  backgroundColor: "#eee",
                  marginTop: 16,
                  borderRadius: 8,
                  padding: 8,
                }}
              >
                <IonLabel style={{ fontWeight: 900 }} position="stacked">
                  Your Tinkan🛢 needs purpose{" "}
                </IonLabel>
                <IonTextarea
                  value={description}
                  onIonChange={(e) => {
                    _description(e.detail.value);
                  }}
                  rows={6}
                  cols={20}
                  placeholder="👉 share your stories about... &#10;🧠 have you ever wondered why... &#10;🥷 collecting tid bits for..."
                />
              </div>
              <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
                <IonItem lines="none">
                  <IonLabel>
                    &nbsp;
                    {isPrivate
                      ? " Closed - Only you can add a story"
                      : " Open - Anyone can add a story"}
                  </IonLabel>
                  <IonToggle
                    color={"secondary"}
                    checked={isPrivate}
                    onIonChange={(e) => {
                      _isPrivate(e.detail.checked);
                    }}
                    slot="start"
                  ></IonToggle>
                </IonItem>
              </div>

              <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
                <IonItem lines="none">
                  <IonLabel>
                    &nbsp;
                    {allowCaptions ? " Captions - On" : " Captions - Off"}
                  </IonLabel>
                  <IonToggle
                    color={"secondary"}
                    checked={isPrivate}
                    onIonChange={(e) => {
                      _isPrivate(e.detail.checked);
                    }}
                    slot="start"
                  ></IonToggle>
                </IonItem>
              </div>

              <div style={{ width: "100%", marginLeft: -18, marginTop: 10 }}>
                <IonItem lines="none">
                  <IonLabel>
                    &nbsp;
                    {isPromoted
                      ? " Public - For the world to see"
                      : " Private - Use a secret link🚪🤫"}
                  </IonLabel>
                  <IonToggle
                    color={"secondary"}
                    checked={isPromoted}
                    onIonChange={(e) => {
                      _isPromoted(e.detail.checked);
                    }}
                    slot="start"
                  ></IonToggle>
                </IonItem>
              </div>

              {props.group && props.group.name ? (
                <>
                  <IonButton
                    onClick={() => {
                      Db.updateDocument(
                        DATABASE_ID,
                        COLLECTION_ID.groups,
                        props.group.$id,
                        {
                          name,
                          description,
                          emoji,
                          imageId,
                        }
                      );
                    }}
                    expand="block"
                    color="secondary"
                  >
                    Update Group
                  </IonButton>
                </>
              ) : (
                <IonButton
                  disabled={!name || !description}
                  onClick={() => {
                    presentLoading({
                      message: `“Triangulating Now 📡” ―Tinkan`,
                      duration: 5000,
                      spinner: "circles",
                    });

                    let lats: any = [];
                    let lons: any = [];

                    let intervalCount = 0;
                    if (type != "topic") {
                      let interval = setInterval(() => {
                        navigator.geolocation.getCurrentPosition((position) => {
                          if (position.coords.longitude != 0) {
                            lats.push(position.coords.latitude);
                            lons.push(position.coords.longitude);
                            intervalCount++;
                            console.log(
                              "position:",
                              position.coords.longitude,
                              position.coords.latitude,
                              intervalCount
                            );
                          }

                          // Kill it
                          if (intervalCount == 4) {
                            clearInterval(interval);

                            Db.createDocument(
                              DATABASE_ID,
                              COLLECTION_ID.groups,
                              "unique()",
                              {
                                name: name,
                                description: description,
                                createdBy: user.$id,
                                lon: _.sum(lons) / intervalCount,
                                lat: _.sum(lats) / intervalCount,
                              }
                            ).then((g) => {
                              Db.createDocument(
                                DATABASE_ID,
                                COLLECTION_ID.groupMembers,
                                "unique()",
                                {
                                  groupId: g.$id,
                                  userId: user.$id,
                                  isPromoted: isPromoted,
                                  isPrivate: isPrivate,
                                }
                              ).then((data) => {
                                dismissLoading();
                                props.dismiss();
                                setTimeout(() => {
                                  history.push(`/group/${g.$id}`);
                                }, 500);
                                _name(undefined);
                                _emoji(undefined);
                                _description(undefined);
                              });
                            });
                          }
                          if (intervalCount > 4) {
                            clearInterval(interval);
                          }
                        });
                      }, 1000);
                    } else {
                      Db.createDocument(
                        DATABASE_ID,
                        COLLECTION_ID.groups,
                        "unique()",
                        {
                          name: name,
                          description: description,
                          createdBy: user.$id,
                          lon: 0,
                          lat: 0,
                        }
                      ).then((g) => {
                        props.dismiss();
                        setTimeout(() => {
                          history.push(`/group/${g.$id}`);
                        }, 500);
                        Db.createDocument(
                          DATABASE_ID,
                          COLLECTION_ID.groupMembers,
                          "unique()",
                          {
                            groupId: g.$id,
                            userId: user.$id,
                          }
                        ).then((data) => {
                          dismissLoading();
                          _name(undefined);
                          _emoji(undefined);
                          _description(undefined);
                        });
                      });
                    }
                  }}
                  style={{ marginTop: 30 }}
                  expand="block"
                  color="secondary"
                >
                  DROP TINKAN🛢
                </IonButton>
              )}
            </>
          )}
        </IonContent>
      </IonModal>
    </>
  );
}
