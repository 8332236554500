import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import User from "../services/users";
import EmojiSelector from "../components/EmojiSelector";
import PageImage from "../components/Image/PageImage";
import PageRemind from "../components/Remind/PageRemind";
import PageNote from "../components/Note/PageNote";
import PageQuestion from "../components/Question/PageQuestion";
import PageContact from "../components/Contact/PageContact";
import PageFact from "../components/Fact/PageFact";
import PageEvent from "../components/Event/PageEvent";
import PageFile from "../components/File/PageFile";
import PageAlarm from "../components/Alarm/PageAlarm";
import PageLink from "../components/Link/PageLink";
import PagePoll from "../components/Poll/PagePoll";
import PageDirections from "../components/Directions/PageDirections";
import PageGorilla from "../components/Gorilla/PageGorilla";
import { useHistory, useParams } from "react-router";
import { useRecoilState } from "recoil";
import { membersState, playlistState, userState } from "../services/state";
import PageImageSlider from "../components/Image/PageImageSlider";
import RemotePlugin from "../components/Plugin/RemotePlugin";

interface Props {
  note?: any;
  show?: boolean;
  // dismiss: () => void;
  group?: any;
}

interface Params {
  id: string;
  group: string;
}

export default function BlockPage(props: Props) {
  const [emoji, _emoji] = useState();
  const [item, _item] = useState<any>();
  const [user, _user] = useRecoilState<any>(userState);
  const [playlist, _playlist] = useRecoilState(playlistState);
  const params = useParams<Params>();
  const [members, _members] = useRecoilState<any>(membersState);
  const [groupsDetails, _groupsDetails] = useState<any>();
  const [isPublic, _isPublic] = useState<any>(false);
  const [typeId, _typeId] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    Db.getDocument(DATABASE_ID, COLLECTION_ID.blocks, params.id).then(
      (data) => {
        _item(data);
        _emoji(data.emoji);
        _typeId(data.typeId);
        console.log(data);
        console.log(user);
        console.log("props.group", props.group);

        Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, data.groupId).then(
          (data) => {
            _groupsDetails(data);
            console.log("groupsDetails", data);
          }
        );
      }
    );

    let url = window.location.href;
    if (url.includes("t/")) {
      _isPublic(true);
    }
  }, []);

  return (
    <>
      {typeId == "plugin" && user && groupsDetails && item && (
        <>
          <RemotePlugin
            item={item}
            user={user}
            group={groupsDetails}
            type="page"
          />
        </>
      )}
      {/* Image */}
      {(emoji == "1f4f8" || emoji == "1f3a5") && (
        <>
          <PageImage
            item={item}
            emoji={emoji}
            canEdit={item.createdBy == user.accountId}
            update={(data) => {
              _item(data);
            }}
            isPublic={isPublic}
          />
        </>
      )}
      {/* Remind */}
      {emoji == "1f9e0" && (
        <PageRemind item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Question */}
      {emoji == "1f91a" && (
        <PageQuestion item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Message */}
      {emoji == "1f4ac" && (
        <PageNote item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Contact */}
      {emoji == "260e-fe0f" && (
        <PageContact item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Fact */}
      {emoji == "1f914" && (
        <PageFact item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Event */}
      {emoji == "1f4c5" && (
        <PageEvent item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* File */}
      {emoji == "1f5c3-fe0f" && (
        <PageFile item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Alarm */}
      {emoji == "1f6a8" && (
        <PageAlarm item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Link */}
      {emoji == "1f517" && (
        <PageLink item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Poll */}
      {emoji == "1f4ca" && (
        <PagePoll item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Directions */}
      {emoji == "1f697" && (
        <PageDirections item={item} emoji={emoji} isPublic={isPublic} />
      )}
      {/* Gorilla */}
      {emoji == "1f98d" && (
        <PageGorilla item={item} emoji={emoji} isPublic={isPublic} />
      )}
    </>
  );
}
