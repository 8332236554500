import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  calendar,
  calendarOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  server,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import UserAccount from "../services/account";
import { userState } from "../services/state";
import User from "../services/users";

function MagicLink() {
  const [selectedSegment, _selectedSegment] = useState<any>("alldates");
  const [email, _email] = useState<any>();
  const [password, _password] = useState<any>();
  const [errorMessage, _errorMessage] = useState<any>();
  const [skippedHomeScreen, _skippedHomeScreen] = useState(false);

  const history = useHistory();

  const [user, _user] = useRecoilState<any>(userState);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId: any = params.get("userId");
  const secret: any = params.get("secret");

  useEffect(() => {
    console.log(params);
    const promise = UserAccount.updateMagicURLSession(userId, secret).then(
      (data) => {
        console.log("data", data);

        UserAccount.get().then((userData) => {
          console.log("user", userData);
          localStorage.setItem("user", JSON.stringify(userData));
          history.push("/home");
        });
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding">Magic Link</IonContent>
    </IonPage>
  );
}

export default MagicLink;
