import { IonButton } from "@ionic/react";
import getStripe from "./stripe";


export default function CheckoutCredits(accountId, price, mode) {
    let host = window.location.host;
    if (host === "localhost:8100") { host = "http://" + host; } else { host = "https://" + host; }

    console.log("account", host);
    async function handleCheckout() {
        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                    price: price,
                    quantity: 1,
                },
            ],
            mode: mode,
            successUrl: host + "/credit_success/{CHECKOUT_SESSION_ID}",
            cancelUrl: host + "/credits",
            
            clientReferenceId: accountId
        });
        console.warn(error.message);
    }

    return <IonButton onClick={handleCheckout}>Purchase</IonButton>;
}