import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { close, closeCircleOutline, play, playCircle } from "ionicons/icons";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import Db from "../../services/db";
import {
  accountState,
  playlistStateIndex,
  userState,
} from "../../services/state";
import { imageUrl, videoUrl } from "../../services/storage";
import { emojisSelectUnified } from "../EmojiSelector";
import GalleryItem from "../GalleryItem";
import PhotoGallery from "../PhotoGallery";
import StoredImage from "../StoredImage";
import Microphone from "./Microphone";
import LightBox from "./LightBox";
import { Waypoint } from "react-waypoint";

interface Props {
  item: any;
  emoji: string;
  showDetails: boolean;
  clicked: () => void;
  clickedPlay: () => void;
}

export default function BlockImage(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const [account, _account] = useRecoilState<any>(accountState);
  const [user, _user] = useRecoilState<any>(userState);
  const [playing, setPlaying] = useState(true);
  const [playlistIndex, _playlistIndex] = useRecoilState(playlistStateIndex);
  const [audioSaved, _audioSaved] = useState(false);
  const [audioID, _audioID] = useState<any>();
  const [createdBy, _createdBy] = useState<any>();
  const [chatCount, _chatCount] = useState<any>([]);
  const history = useHistory();

  const videoRef = useRef(null);
  // console.log("item", props.item);
  useEffect(() => {
    // Db.listDocuments("tinkan", "messages", [
    //   Query.equal("relatedId", props.item.$id),
    // ]).then((res) => {
    //   // console.log("res", res);
    //   _chatCount(res.total);
    // });
  }, []);

  return (
    <>
      <div style={{ paddingTop: 0 }}>
        <div
          style={{
            display: "inline-block",
            padding: 0,
            marginBottom: 10,
            borderRadius: 8,
            width: "calc(100% - 0px)",
            overflow: "hidden",
          }}
        >
          {props.emoji == "1f3a5" ? (
            <div
              onClick={() => {
                _playlistIndex(props.item.$id);
                props.clicked();
              }}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                height: 300,
                backgroundSize: "cover",
                backgroundColor: "black",
                backgroundPosition: "top",
                borderRadius: 16,
                border: "4px solid black",
                boxShadow: "-2px 4px 0px 0px #005e5a",
                overflow: "hidden",
                display: "inline-block",
                position: "relative",
              }}
            >
              <ReactPlayer
                onReady={() => {
                  setTimeout(() => {
                    if (videoRef.current) {
                      setPlaying(false);
                    }
                  }, 3000);
                }}
                playing={playing}
                ref={videoRef}
                muted={true}
                width={"100%"}
                height={"auto"}
                playsinline={true}
                url={videoUrl(props.item.imageId)}
              />

              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  textAlign: "right",
                  color: "black",
                  padding: 4,
                  fontWeight: 900,
                }}
              >
                <IonIcon icon={playCircle} style={{ fontSize: 40 }} />
              </div>
              {props.item.name && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    margin: 10,
                  }}
                >
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingBottom: 5,
                      background: "black",
                      width: "fit-content",
                      margin: "auto",
                      borderRadius: 10,
                      padding: 10,
                      boxShadow: "0px 0px 5px 0px #000000",
                      maxHeight: 270,
                      overflow: "auto",

                      color: "white",
                      zIndex: 100,
                    }}
                  >
                    {props.item.name ? props.item.name : null}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                _playlistIndex(props.item.$id);
                props.clicked();
              }}
              style={{
                marginLeft: 0,
                width: "100%",
                height: 300,
                background:
                  "url(" + imageUrl(props.item.imageId, true, 6, 280) + ")",
                backgroundSize: "cover",
                backgroundPosition: "top",
                borderRadius: 16,
                border: "4px solid black",
                boxShadow: "-2px 4px 0px 0px #005e5a",
                overflow: "hidden !important",
                display: "inline-block",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  color: "white",
                  padding: 10,
                  fontWeight: 900,
                  textAlign: "left",
                }}
              >
                {props.item.name && (
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      paddingBottom: 5,
                      background: "black",
                      width: "fit-content",
                      margin: "auto",
                      borderRadius: 10,
                      padding: 10,
                      boxShadow: "0px 0px 5px 0px #000000",
                      maxHeight: 270,
                      overflow: "auto",
                    }}
                  >
                    {props.item.name ? props.item.name : null}
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              fontWeight: "bold",
              display: "inline-block",
              paddingBottom: 5,
              verticalAlign: "top",
              width: "calc(100% - 110px)",
              paddingLeft: 10,
            }}
            onClick={() => {
              _playlistIndex(props.item.$id);
              props.clicked();
            }}
          >
            <div
              className="block-subtext"
              style={{ textAlign: "left", width: "100%", paddingBottom: 5 }}
              onClick={() => {
                _playlistIndex(props.item.$id);
                props.clicked();
              }}
            >
              {moment(props.item.$createdAt).fromNow()}
              {chatCount > 0 && (
                <span style={{ marginLeft: 10 }}>
                  💬 <span style={{ fontWeight: "bold" }}>{chatCount}</span>
                </span>
              )}
            </div>
            <div
              onClick={() => {
                _playlistIndex(props.item.$id);
                props.clicked();
              }}
              style={{ fontWeight: "normal", fontSize: 14 }}
            >
              {/* add some lorem ipsum */}
              {props.item.body}
            </div>

            {props.item.audioId && (
              <div
                style={{
                  fontSize: "small",
                  paddingLeft: 0,
                  marginBottom: 4,
                  marginTop: 4,
                  cursor: "pointer",
                }}
                onClick={() => {
                  _playlistIndex(props.item.$id);
                  props.clickedPlay();
                }}
              >
                <div color="light">🎧 Listen</div>
              </div>
            )}
            {account.$id == props.item.createdBy && !props.item.body && (
              <div
                style={{
                  fontSize: "small",
                  paddingLeft: 4,

                  marginBottom: 4,
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/block/edit/" + props.item.$id);
                  }}
                  color="light"
                >
                  ➕ Add Some Details
                </div>
              </div>
            )}
            {props.item.captions && props.item.captions.length > 0 && (
              <div
                style={{
                  fontSize: "small",
                  paddingLeft: 4,
                }}
              >
                <div
                  onClick={() => {
                    history.push(
                      "/group/" +
                        props.item.groupId +
                        "/block/" +
                        props.item.$id
                    );
                  }}
                  color="light"
                >
                  💬 {props.item.captions.length} Captions
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
