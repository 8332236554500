import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonModal,
  IonRow,
} from "@ionic/react";
import { Query } from "appwrite";
import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { useRecoilState, useResetRecoilState } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { membersState, userState } from "../services/state";
import { imageUrl } from "../services/storage";
import GalleryItem from "./GalleryItem";
import PhotoGallery from "./PhotoGallery";
import StoredImage from "./StoredImage";

interface Props {
  groupId: any;
}

export default function Members(props: Props) {
  const [members, _members] = useRecoilState<any>(membersState);
  const [user, _user] = useRecoilState<any>(userState);

  const history = useHistory();

  function List() {
    const [showModal, setShowModal] = useState<string>("");
    return (
      members &&
      members.map((member: any) => {
        // get the memebers picture as an avatar
        return (
          <div
            key={member.$id}
            style={{
              display: "inline-block",
              margin: 5,
              width: 70,
              textAlign: "center",
            }}
          >
            {member.$id == user.$id ? (
              <>
                <IonAvatar
                  onClick={() => {
                    history.push("/me");
                  }}
                  style={{
                    border: "solid 4px black",
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",

                    verticalAlign: "top",
                    backgroundColor: "grey",
                    display: "inline-block",
                  }}
                >
                  <StoredImage
                    src={
                      member.imageId
                        ? imageUrl(member.imageId)
                        : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                    }
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      objectPosition: "top",
                    }}
                    alt="item"
                  />
                </IonAvatar>
              </>
            ) : (
              <IonAvatar
                onClick={() => {
                  setShowModal(member.$id);
                }}
                style={{
                  border: "solid 4px black",
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                <StoredImage
                  src={
                    member.imageId
                      ? imageUrl(member.imageId)
                      : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                  }
                />
              </IonAvatar>
            )}
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 12,
                height: 10,
                width: "100%",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              {member?.username?.split(" ")[0].substr(0, 9)}
            </div>

            {/* Modal with memeber details */}
            <IonModal isOpen={showModal == member.$id}>
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  verticalAlign: "top",
                  width: "100%",
                  margin: 5,
                  paddingTop: 60,
                }}
              >
                <div
                  style={{
                    border: "solid 4px black",
                    width: 122,
                    height: 122,
                    borderRadius: 16,
                    marginBottom: 6,
                    boxShadow: "-2px 4px 0px 0px #005e5a",
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                >
                  <StoredImage
                    src={
                      member.imageId
                        ? imageUrl(member.imageId)
                        : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
                    }
                    style={{
                      objectFit: "cover",

                      width: "100%",
                      height: "100%",
                      objectPosition: "top",
                    }}
                    alt="item"
                  />
                </div>
                <div
                  style={{
                    fontSize: 16,
                    verticalAlign: "top",
                    fontWeight: 900,
                    display: "inline-block",
                    marginLeft: 5,
                    width: "calc(100% - 90px)",
                  }}
                >
                  Username: <br />
                  {member.username ? member.username : "Somebody"}
                </div>{" "}
                <div style={{ padding: 20 }}>
                  <IonButton expand="block" onClick={() => setShowModal("")}>
                    Close
                  </IonButton>
                </div>
              </div>
            </IonModal>
          </div>
        );
      })
    );
  }

  return (
    <>
      <List />
    </>
  );
}
