import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Query } from "appwrite";
import {
  calendar,
  calendarOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  server,
  serverOutline,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import SetupProfile from "../components/SetupProfile";
import UserAccount from "../services/account";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, loginUserState, userState } from "../services/state";
import User from "../services/users";

function SetupUser() {
  const [selectedSegment, _selectedSegment] = useState<any>("alldates");
  const [code, _code] = useState<any>();
  const [password, _password] = useState<any>();
  const [errorMessage, _errorMessage] = useState<any>();
  const [skippedHomeScreen, _skippedHomeScreen] = useState(false);
  const [loginUser, _loginUser] = useRecoilState<any>(loginUserState);

  const history = useHistory();

  const [user, _user] = useRecoilState<any>(userState);
  const [account, _account] = useRecoilState<any>(accountState);

  return (
    <div style={{ padding: 24, textAlign: "center" }}>
      <h1>Setup User</h1>
      <SetupProfile />
    </div>
  );
}

export default SetupUser;
