import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  calendar,
  ellipse,
  fileTray,
  fileTrayFull,
  images,
  listCircle,
  people,
  serverOutline,
  square,
  triangle,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./theme/style.css";
import Home from "./pages/Home";
import Group from "./pages/Group";
import { useEffect, useState } from "react";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import Saved from "./pages/Saved";
import EmojiPage from "./pages/Emoji";
import InputPage from "./pages/InputPage";
import BlockPage from "./pages/BlockPage";
import MagicLink from "./pages/MagicLink";
import Code from "./pages/Code";
import SetupUser from "./pages/SetupUser";
import PowerUps from "./pages/PowerUps";
import MapPage from "./pages/Map";
import Me from "./pages/Me";
import Credits from "./pages/Credits";
import CreditsSuccess from "./pages/CreditSuccess";

setupIonicReact();

function App() {
  let localUser = localStorage.getItem("user");

  return (
    <RecoilRoot>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            {localUser ? (
              <>
                <Route exact path="/home">
                  <Home />
                </Route>
                <Route exact path="/login">
                  <Home />
                </Route>
                <Route exact path="/signup">
                  <Home />
                </Route>
                <Route exact path="/saved">
                  <Saved />
                </Route>
                <Route exact path="/powerups/:groupId">
                  <PowerUps />
                </Route>
                <Route exact path="/emoji">
                  <EmojiPage />
                </Route>
                <Route exact path="/group/:group/block/new">
                  <InputPage />
                </Route>
                <Route exact path="/group/:group/block/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/group/:group/block_/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/t/:group/block/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/t/:group/block_/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/block/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/block/edit/:block">
                  <InputPage />
                </Route>
                <Route exact path="/setup_user">
                  <SetupUser />
                </Route>
                <Route exact path="/me">
                  <Me />
                </Route>
                <Route exact path="/credits">
                  <Credits />
                </Route>
                <Route exact path="/credit_success/:sessionId">
                  <CreditsSuccess />
                </Route>
                <Route exact path="/group/:id">
                  <Group />
                </Route>
                <Route exact path="/t/:id">
                  <Group />
                </Route>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/map">
                  <MapPage />
                </Route>
              </>
            ) : (
              <>
                <Route exact path="/home">
                  <Login />
                </Route>
                <Route exact path="/group/:group/block/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/group/:group/block_/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/t/:group/block/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/t/:group/block_/:id">
                  <BlockPage />
                </Route>
                <Route exact path="/map">
                  <MapPage />
                </Route>
                <Route exact path="/group/:id">
                  <Group />
                </Route>
                <Route exact path="/t/:id">
                  <Group />
                </Route>
                <Route exact path="/signup">
                  <SignUp />
                </Route>
                <Route exact path="/magiclink">
                  <MagicLink />
                </Route>
                <Route exact path="/code">
                  <Code />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/">
                  <Login />
                </Route>
              </>
            )}
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </RecoilRoot>
  );
}
export default App;
