import { power } from "ionicons/icons";
import React, { useEffect } from "react";

if (!window.remoteComponentClick) {
  window.addEventListener("remoteComponentClick", (event) => {
    console.log("remoteComponentClick", event.detail.id);

    // props.onRemoteComponentClick(event.detail.id);
    window.location.href = "/block/" + event.detail.id;
  });
}

function RemoteComponent(props) {
  // Use jsDelivr or another CDN that correctly sets Content-Type headers for JavaScript files

  useEffect(() => {
    console.log("RemoteComponent props", props);

    const fetchData = async () => {
      let sendProps = {
        item: props.item,
        user: props.user,
        group: props.group,
        type: props.type,
      }

      let powerUpId = props.item.powerUpId;
      let powerUps = JSON.parse(props.group.powerUps);

      let src = "";

      await powerUps.forEach((powerUp) => {
        if (powerUp.powerUpId == powerUpId) {
          src = powerUp.src;
        }
      }
      );
      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      script.onload = () => {
        // Now check for window.remoteComponent inside the onload handler
        if (window.remoteComponent && window.remoteComponent.render) {
          window.remoteComponent.render(
            "remote-container",
            sendProps,
          );
        }
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    fetchData();

    // add event listener for remoteComponentClick if it doesn't exist



  }, []);



  return (
    <div>
      <div id="remote-container">
        Plugin Loading...
      </div>
    </div>
  );
};

export default RemoteComponent;
