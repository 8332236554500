import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import User from "../services/users";
import EmojiSelector from "../components/EmojiSelector";
import PageImage from "../components/Image/PageImage";
import PageRemind from "../components/Remind/PageRemind";
import PageNote from "../components/Note/PageNote";
import PageQuestion from "../components/Question/PageQuestion";
import PageContact from "../components/Contact/PageContact";
import PageFact from "../components/Fact/PageFact";
import PageEvent from "../components/Event/PageEvent";
import PageFile from "../components/File/PageFile";
import PageAlarm from "../components/Alarm/PageAlarm";
import PageLink from "../components/Link/PageLink";
import PagePoll from "../components/Poll/PagePoll";
import PageDirections from "../components/Directions/PageDirections";
import PageGorilla from "../components/Gorilla/PageGorilla";
import { useHistory, useParams } from "react-router";
import { appFunction } from "../services/function";

interface Params {
  groupId: string;
}

export default function PowerUps() {
  const [emoji, _emoji] = useState();
  const [group, _group] = useState<any>();
  const params = useParams<Params>();

  const history = useHistory();

  useEffect(() => {
    Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, params.groupId).then(
      (data) => {
        _group(data);
        console.log(data);
      }
    );
  }, []);

  function Item(itemProp: any) {
    const [showModal, _showModal] = useState(false);
    const [showPowerUp, _showPowerUp] = useState(true);
    return (
      <>
        {showPowerUp && (
          <>
            <IonItem
              onClick={() => {
                _showModal(true);
              }}
            >
              <div
                style={{
                  border: "3px solid black",
                  padding: 5,
                  borderRadius: 8,
                }}
                slot="start"
              >
                <Emoji unified={itemProp.item.emoji} />
              </div>
              <IonLabel>
                {itemProp.item.title}
                <div style={{ fontSize: 12 }}>{itemProp.item.subtitle}</div>
              </IonLabel>
              <IonChip slot="end">{itemProp.item.tag}</IonChip>
            </IonItem>
            <IonModal isOpen={showModal}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons
                    onClick={() => {
                      _showModal(false);
                    }}
                    slot="start"
                  >
                    <IonButton>Cancel</IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent style={{ textAlign: "center" }}>
                <div
                  style={{
                    border: "3px solid black",
                    padding: 5,
                    borderRadius: 8,
                    width: 70,
                    marginTop: 50,
                    marginLeft: "calc(50% - 35px)",
                  }}
                >
                  <Emoji unified={itemProp.item.emoji} />
                </div>
                <h1>{itemProp.item.title}</h1>
                <IonChip>{itemProp.item.tag}</IonChip>
                <IonGrid>
                  <IonRow>
                    <IonCol size="8" offset="2">
                      {itemProp.item.description}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol style={{ marginTop: 20 }}>
                      {itemProp.item.price == 0 && (
                        <IonButton
                          onClick={() => {
                            let newPowerUps = [];
                            if (group.powerups) {
                              newPowerUps = group.powerups;
                            }

                            newPowerUps.push(itemProp.item.emoji);
                            Db.updateDocument(
                              DATABASE_ID,
                              COLLECTION_ID.groups,
                              group.$id,
                              {
                                powerups: newPowerUps,
                              }
                            ).then(() => {
                              _showModal(false);
                              _showPowerUp(false);
                            });
                          }}
                        >
                          {itemProp.item.button}
                        </IonButton>
                      )}
                      {itemProp.item.price != 0 && (
                        <>
                          <IonButton
                            href={
                              itemProp.item.paymentLink +
                              "?client_reference_id=" +
                              group.$id +
                              "&payment_intent_data-metadata=" +
                              group
                            }
                            target="_blank"
                          >
                            ${itemProp.item.price} - {itemProp.item.button}
                          </IonButton>

                          <IonButton
                            onClick={() => {
                              let data = {
                                groupId: group.$id,
                                emoji: itemProp.item.emoji,
                              };
                              appFunction.createExecution(
                                "637d6194e8794e6cfebe",
                                JSON.stringify(data)
                              );
                            }}
                          >
                            Test
                          </IonButton>
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </>
        )}
      </>
    );
  }

  return (
    <>
      {" "}
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons
              onClick={() => {
                history.goBack();
              }}
              slot="start"
            >
              <IonButton>Cancel</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent class="ion-padding">
          <h1>Available Power Ups</h1>
          <IonList>
            {group && !group.powerups.includes("260e-fe0f") && (
              <Item
                key="1"
                item={{
                  tag: "free",
                  price: 0,
                  title: "Contact",
                  subtitle: "share virtual business cards",
                  emoji: "260e-fe0f",
                  button: "Activate Power Up",
                  description:
                    "Give your TINKAN the power to share contact info. Users can create multiple virtual contact cards that can be downloaded and added to your phones address book directly ",
                }}
              />
            )}

            {group && !group.powerups.includes("1f4ca") && (
              <Item
                key="2"
                item={{
                  tag: "paid",
                  price: 1,
                  title: "Poll",
                  subtitle: "simple anonymous polls",
                  emoji: "1f4ca",
                  paymentLink: "https://buy.stripe.com/test_00g6rlfr45H51yw000",
                  button: "One Time",
                  description:
                    "Activate the power of voice.  Give your crowd the ability to provide feedback through anonymous polls. Add multiple 1 question 3 answer polls",
                }}
              />
            )}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
}
