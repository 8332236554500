import { Client, Account, ID, Teams } from 'appwrite';
import { HOST_URI, PROJECT_ID } from './db';


const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

const UserTeams = new Teams(client);




export default UserTeams;