import { Client, Account, ID } from 'appwrite';
import { HOST_URI, PROJECT_ID } from './db';


const client = new Client()
    .setEndpoint(HOST_URI) // Your API Endpoint
    .setProject(PROJECT_ID);               // Your project ID

let account = new Account(client);


const User = {
    create() {

        account.create(
            ID.unique(),
            'me@example.com',
            'password',
            'Jane Doe'
        ).then(response => {
            console.log(response);
            account = response;
        }, error => {
            console.log(error);
        });
    },
    setPref() {


        const promise = account.updatePrefs({
            fname: "Jane"
        });

        promise.then(function (response) {
            console.log(response); // Success
        }, function (error) {
            console.log(error); // Failure
        });
    },
    getAccount() {
        return account.get();
    },

    login(email, password) {
        console.log("email", email, "password:", password)
        return account.createEmailSession(email, password);
    }
}

export default User;