import {
  IonAvatar,
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToggle,
} from "@ionic/react";
import { Query } from "appwrite";
import { Emoji } from "emoji-picker-react";
import { flameOutline, shareOutline, shareSocialOutline } from "ionicons/icons";
import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, membersState, userState } from "../services/state";
import { imageUrl } from "../services/storage";
import Members from "./Members";
import TechnicalStuff from "./TechinalStuff";
import StoredImage from "./StoredImage";

interface Props {
  name: string;
  group: any;
  updateGroup: () => void;
}

export default function People(props: Props) {
  const [account, _account] = useRecoilState<any>(accountState);
  const [user, _user] = useRecoilState<any>(userState);
  const [members, _members] = useRecoilState<any>(membersState);

  const [users, _users] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    console.log("people", members);
    Db.listDocuments(DATABASE_ID, COLLECTION_ID.users, [
      Query.equal("$id", _.map(members, "userId")),
    ]).then((usersData) => {
      _users(usersData.documents);
    });
  }, []);

  function ListItem(itemProps: any) {
    //console.log("item", props.item);
    return (
      <div
        style={{
          textAlign: "center",
          display: "inline-block",
          verticalAlign: "top",
          width: 70,
          margin: 5,
        }}
      >
        <div
          style={{
            border:
              itemProps.item.activity != true
                ? "solid 4px #ccc"
                : "solid 4px green",
            width: 70,
            height: 70,
            borderRadius: 70,
            overflow: "hidden",
          }}
          onClick={() => {
            history.push("/group/" + itemProps.item.$id);
          }}
        >
          <StoredImage
            style={{ borderRadius: 56 }}
            src={
              itemProps.item.imageId
                ? imageUrl(itemProps.item.imageId)
                : "https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg"
            }
          />
        </div>
        <div style={{ fontSize: 9, fontWeight: 900 }}>
          {itemProps.item?.username}
        </div>
      </div>
    );
  }

  function List() {
    return (
      users &&
      users.map((item: any, index: number) => {
        return <ListItem key={index} item={item} />;
      })
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div
          style={{
            paddingTop: 90,
            position: "sticky",
            top: 15,
            left: 0,
            paddingBottom: 4,
            backgroundColor: "white",
          }}
        >
          {props.group?.description}
        </div>

        <div style={{ marginBottom: 7, marginTop: 25, textAlign: "center" }}>
          <Members groupId={props.group.$id} />
        </div>
        {user && props.group && user.$id == props.group.createdBy ? (
          <TechnicalStuff
            user={user}
            group={props.group}
            updateGroup={() => {
              props.updateGroup();
            }}
          />
        ) : (
          <>
            <div style={{ paddingTop: 50 }}>
              <IonButton
                onClick={() => {
                  console.log(members);
                  Db.listDocuments(DATABASE_ID, COLLECTION_ID.groupMembers, [
                    Query.equal("userId", user.$id),
                    Query.equal("groupId", props.group.$id),
                  ]).then((data: any) => {
                    console.log("data", data);
                    if (data.documents.length != 0) {
                      Db.deleteDocument(
                        DATABASE_ID,
                        COLLECTION_ID.groupMembers,
                        data.documents[0].$id
                      ).then(async (data: any) => {
                        console.log("data", data);
                        // dismissLoading()
                        history.replace("/home");
                      });
                    }
                  });
                }}
                color="dark"
                shape="round"
                expand="block"
                size="small"
              >
                Leave Crowd
              </IonButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
