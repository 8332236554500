import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import {
  calendar,
  calendarOutline,
  people,
  peopleCircleOutline,
  person,
  personCircle,
  personCircleOutline,
  server,
  serverOutline,
} from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import AllDates from "../components/AllDates";
import MyGroups from "../components/NearGroups";
import MyProfile from "../components/MyProfile";
import UserAccount from "../services/account";
import Db, { COLLECTION_ID, DATABASE_ID } from "../services/db";
import { accountState, userState } from "../services/state";
import User from "../services/users";

function SignUp() {
  const [account, _account] = useRecoilState<any>(accountState);
  const [user, _user] = useRecoilState<any>(userState);

  const [selectedSegment, _selectedSegment] = useState<any>("alldates");
  const [email, _email] = useState<any>();

  const [name, _name] = useState<any>();
  const [password, _password] = useState<any>();

  const [presentLoading, dismissLoading] = useIonLoading();

  const history = useHistory();

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div style={{ textAlign: "center" }}>
          <div style={{ fontWeight: 900, fontSize: 80, marginTop: 30 }}>
            <IonIcon icon={serverOutline} />
          </div>
          <div style={{ fontWeight: 900, fontSize: 40 }}>TINKAN</div>

          <h1>Sign Up</h1>
          <div style={{ paddingTop: 24 }}>
            <div>
              Username <br />
              <sub>
                (<b>everyone</b> can see this )
              </sub>
              <br />
              <br />
            </div>
            <IonInput
              style={{ fontSize: 22, fontWeight: 900 }}
              value={name}
              onIonChange={(e) => {
                _name(e.target.value);
              }}
              className="block-input"
              maxlength={60}
              placeholder="clark_kent_01"
            ></IonInput>
            <div style={{ paddingTop: 40 }}>Email</div>
            <IonInput
              value={email}
              onIonChange={(e) => {
                _email(e.target.value);
              }}
              className="block-input"
              placeholder="Enter Email"
            ></IonInput>
            <div style={{ paddingTop: 10 }}>Password</div>
            <IonInput
              type="password"
              value={password}
              onIonChange={(e) => {
                _password(e.target.value);
              }}
              className="block-input"
              placeholder="Enter Password"
            ></IonInput>
          </div>
          <br />
          <br />
          <IonButton
            onClick={() => {
              presentLoading({
                message: `“The greater the difficulty, the more the glory in surmounting it.” ―Epicurus`,
                duration: 2000,
                spinner: "circles",
              });
              UserAccount.create("unique()", email, password).then(
                (data) => {
                  User.login(email, password).then((loginData) => {
                    _account(loginData);
                    console.log(loginData); // Success
                    localStorage.setItem("user", JSON.stringify(loginData));

                    Db.createDocument(
                      DATABASE_ID,
                      COLLECTION_ID.users,
                      "unique()",
                      {
                        accountId: data.$id,
                        username: name,
                      }
                    ).then((u) => {
                      UserAccount.updateName(u.$id).then(() => {
                        window.location.href = "/home";
                      });
                    });
                  });
                },
                (error) => {
                  alert(error);
                }
              );
            }}
            expand="block"
            color="dark"
          >
            Sign Up
          </IonButton>
          <br />
          <br />
          <IonButton
            onClick={() => {
              history.push("/login");
            }}
            expand="block"
            size="small"
            color="light"
          >
            Login
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default SignUp;
