import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import moment from "moment";
import { useEffect, useState } from "react";
import Db, { COLLECTION_ID, DATABASE_ID } from "../../services/db";
import User from "../../services/users";
import EmojiSelector from "../EmojiSelector";
import { useForm } from "react-hook-form";
import { Permission, Role } from "appwrite";
import { appFunction } from "../../services/function";

interface Props {
  emoji: string;
  group?: string;
  block?: string;
  submitted: () => void;
}

export default function InputNote(props: Props) {
  const [name, _name] = useState<any>();
  const [body, _body] = useState<any>();
  const [orderId, _orderId] = useState<any>();
  const [type, _type] = useState<any>();
  const [block, _block] = useState<any>();
  const [showLoading, dismissLoading] = useIonLoading();
  const [user, _user] = useState<any>();

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    console.log("props", props);
    if (props.block) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.blocks, props.block).then(
        (data: any) => {
          setValue("body", data.body);
          setValue("name", data.name);
          _name(data.name);
          setValue("orderId", data.orderId);
          _block(data);
        }
      );
    }
  }, []);

  const onSubmit = (data: any) => {
    console.log(data);
    showLoading();
    console.log("props", props);

    if (props.group) {
      Db.getDocument(DATABASE_ID, COLLECTION_ID.groups, props.group).then(
        (groupData: any) => {
          Db.createDocument(
            DATABASE_ID,
            COLLECTION_ID.blocks,
            "unique()",
            {
              name: data.name,
              emoji: props.emoji,
              body: data.body,
              groupId: groupData.$id,
              createdBy: user.$id,
            },
            [
              Permission.read(Role.any()), // Writers can update this document
              Permission.update(Role.user(user.$id)), // Writers can update this document
              Permission.delete(Role.user(user.$id)), // Writers can update this document
            ]
          ).then(async (blockData) => {
            // send notification to all users in the group

            let message = data.name;
            if (groupData.name) {
              message = groupData.name + "\n \n" + message;
              message =
                message + "\n \n https://a.tinkan.app/group/" + groupData.$id;
            }

            await appFunction
              .createExecution(
                "GroupUpdate",
                JSON.stringify({
                  groupId: groupData.$id,
                  message: message,
                }),
                false
              )
              .then((execution) => {
                console.log("execution", execution);
              });

            if (!props.group) return;
            dismissLoading();
            props.submitted();
          });
        }
      );
    } else if (props.block) {
      // update block
      Db.updateDocument(DATABASE_ID, COLLECTION_ID.blocks, props.block, {
        name: data.name,
        body: data.body,
        orderId: data.orderId,
      }).then((blockData) => {
        dismissLoading();
        props.submitted();
      });
    }
  };

  useEffect(() => {
    User.getAccount().then((data: any) => {
      _user(data);
    });
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            backgroundColor: "#eee",
            borderRadius: 8,
            padding: 8,
            marginTop: 10,
          }}
        >
          <IonLabel style={{ fontWeight: 900 }} position="stacked">
            Write Your Message
          </IonLabel>
          <IonTextarea
            {...register("name")}
            rows={4}
            placeholder="Text goes here"
          />
        </div>

        {name ? (
          <IonButton
            type="submit"
            onClick={() => {}}
            style={{ marginTop: 30 }}
            expand="block"
            color="dark"
            shape="round"
          >
            Save
          </IonButton>
        ) : (
          <IonButton
            type="submit"
            onClick={() => {}}
            style={{ marginTop: 30 }}
            expand="block"
            color="dark"
            shape="round"
          >
            Send Message
          </IonButton>
        )}
        {props.block && (
          <IonButton
            size="small"
            color={"danger"}
            onClick={() => {
              if (!props.block) return;
              Db.deleteDocument(
                DATABASE_ID,
                COLLECTION_ID.blocks,
                props.block
              ).then(() => {
                props.submitted();
              });
            }}
            style={{ marginTop: 60 }}
            expand="block"
            shape="round"
          >
            Delete
          </IonButton>
        )}
      </form>
    </>
  );
}
